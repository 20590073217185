import { Button } from "react-bootstrap"

export const AddAssignmentButton: React.FC<{onClick: () => void}> = ({ onClick }) => {
    return (
        <Button
            key="add-assignment-btn"
            className="class-assignment-add" 
            onClick={onClick} 
            data-testid="table-add-assignment" 
            variant="theme">
                <i className="fas fa-plus pe-2" />
                <span className="d-none d-md-inline">Assignment</span>
        </Button>
    )
}
