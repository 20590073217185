import { AuthContext } from 'AuthContext'
import styles from "../../components/Assignment/styles.module.scss"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { AssignmentContext } from 'components/Assignment/AssignmentContext'
import { toast } from 'react-toastify'
import { highlightIncompleteLessonQuestions, saveGuidedLesson, saveLessonInterval, saveTopic } from 'components/Lessons/LessonModule/helpers/lessonHelper'
import { useModel } from '@stem-sims/nexus'
import TopicSection from 'components/Curriculum/Sections/TopicSection'
import assessments, { AssessmentResponse } from 'shared/routes/assessments/assessments'
import AssignmentLesson from './AssignmentLesson'
import { Type } from 'shared/types/moduleTypes'
import AssignmentAssessment from './AssignmentAssessment'
import CustomModal from 'components/Modals/CustomModal'
import { highlightIncompleteTopicQuestions, loadInitialAssessmentData, loadInitialAssessmentDemoData, loadInitialLessonData, loadInitialLessonDemoData } from './helpers/topicAssignmentHelper'
import AssignmentTopicProgress from './AssignmentTopicProgress'
import { checkIfAssessmentComplete } from './helpers/assessmentHelper'
import { submitAssessment } from 'models/modules'
import { formHasSufficientData, parseFormData } from 'helpers/formDataHelper'
import lessons, { LessonResponse } from 'shared/lessons'
import { CurriculumSectionType, TopicStudentAnswers } from 'shared/types/curriculumTypes'
import assignments from 'shared/routes/assignments'
import { AssignmentTopicContext } from './AssignmentTopicContext'
import books from 'shared/routes/curriculum/books'
import useBoolean from 'helpers/useBoolean'
import { checkSectionProgress, countCompletedQuestionsInSection, sanitizeSectionHeader, shortenSectionHeader, updateSectionsProgress } from './helpers/topicAssignmentProgressHelper'
import { BeatLoader } from 'react-spinners'
import { DrawingAnswer } from 'shared/types/studentTypes'
import { removeHighlight } from 'helpers/htmlElementHighlight'

export type SectionHeader = {header: string, sectionNumber: number, type: CurriculumSectionType, progress: SectionProgressType, completedQuestions: number, totalQuestions: number}
export type SectionProgressType = "complete" | "incomplete" | "not-visited"

interface AssignmentTopicProps {
    topicId: string
    initialTopicData?: TopicStudentAnswers
    initialAssessmentData?: Record<string, string>
    initialLessonData?: Record<string, string>
    submissionId: string
    requireCompletion?: boolean
    activeVideoId?: string
}

function AssignmentTopic({ topicId, initialTopicData, initialAssessmentData, initialLessonData, submissionId, requireCompletion, activeVideoId } : AssignmentTopicProps) {
    const authContext = React.useContext(AuthContext)
    const { setActivePart, setOnReturn, demoMode } = React.useContext(AssignmentContext)
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent

    const [submitting, setSubmitting] = useState(false)
    const [nextSectionLoading, setNextSectionLoading] = useState(false)

    const resuming = !!initialTopicData
    const [ studentAnswers, setStudentAnswers ] = useState<TopicStudentAnswers>({})
    const [initialStudentAnswers, setInitialStudentAnswers] = useState<TopicStudentAnswers>({})
    const [showCompleteConfirmModal, setShowCompleteConfirmModal] = useState<boolean>(false)
    const [showSectionSwitchConfirm, setShowSectionSwitchConfirm] = useState<boolean>(false)

    const [destinedSection, setDestinedSection] = useState<number>(null)

    const [currentSectionNumber, setCurrentSectionNumber] = useState<number>(0)
    const [sectionHeaders, setSectionHeaders] = useState<SectionHeader[]>([])
    
    const [assessment, setAssessment] = useState<AssessmentResponse | null>(null)
    const [lesson, setLesson] = React.useState<LessonResponse>(null)
    const [refreshProgressCheck, toggleRefreshProgressCheck] = useBoolean(false)
    
    const startTimeRef = React.useRef(new Date().toISOString().slice(0, 19).replace('T', ' '))
    const saveTopicTimerRef = useRef(new Date())
    const prevSavedRef = React.useRef(resuming)

    const topicFormRef = React.useRef(null)

    const [ lessonDrawResponses, setLessonDrawResponses ] = React.useState<Record<string, DrawingAnswer>>({})
    const [ assessmentDrawResponses, setAssessmentDrawResponses ] = React.useState<Record<string, DrawingAnswer>>({})

    const [prevLessonData, setPrevLessonData] = React.useState<Record<string, string>>(null) // needed for going back to lesson section
    const [prevLessonDrawData, setPrevLessonDrawData] = React.useState<Record<string, DrawingAnswer>>(null)
    const [prevAssessmentData, setPrevAssessmentData] = React.useState<Record<string, string>>(initialAssessmentData) // needed for going back to assessment section
    const [prevAssessmentDrawData, setPrevAssessmentDrawData] = React.useState<Record<string, DrawingAnswer>>(null)

    const { response: topic } = useModel({
        model: books.getTopic,
        props: { topicId: topicId }
    })
    const currentSection = topic?.sections?.[currentSectionNumber]
    const lessonSection = !!(currentSection?.type === "Lesson"  && currentSection?.lessonId)
    const assessmentSection = !!(currentSection?.type === "Assessment" && currentSection?.assessmentId)
    const lastSection = topic?.sections?.length - 1 === currentSectionNumber

    const scrollRef = useRef<HTMLDivElement>(null)

    /**
     * Used to initialize empty student answers, current topic section, 
     * available sections in current topic, completed sections, etc.
     */
    useEffect(() => {
        const parser = new DOMParser()
        if (topic?.sections) {
            // initialize the object with essential data such as question id and type to send to backend
            const initialStudentAnswers: TopicStudentAnswers = {}
            const headers: SectionHeader[] = []
            let count = 1
            topic?.sections?.forEach(section => {
                section?.questions?.forEach(question => {
                    initialStudentAnswers[`s${section.sortOrder}-q${question.sortOrder}`] = {topicQuestionId: question.id, answer: "", questionType: question.type, drawingResponse: null}
                })
                const shortHeader = shortenSectionHeader(section?.header, count) // Use numbers instead for long headers

                const sectionHTML = parser.parseFromString(section?.sectionText ?? "", "text/html")
                const tableInputsSection = sectionHTML.querySelectorAll('table input[type="text"], table textarea')?.length ?? 0

                headers.push({
                    header: sanitizeSectionHeader(shortHeader),
                    sectionNumber: section?.sortOrder,
                    type: section.type,
                    progress: "not-visited",
                    completedQuestions: 0,
                    totalQuestions: (section?.questions?.length ?? 0) + tableInputsSection
                })
                count++
            })
            if (resuming) {
                setStudentAnswers(initialTopicData)
                setInitialStudentAnswers(initialTopicData)
                const newHeaders = updateSectionsProgress({initialTopicData, initialAssessmentData, initialLessonData, headers})
                setSectionHeaders(newHeaders)
            } else {
                setStudentAnswers(initialStudentAnswers)
                setInitialStudentAnswers(initialStudentAnswers)
                setSectionHeaders(headers)
            }
        }
    }, [topic, resuming, initialTopicData, initialLessonData, initialAssessmentData])

    useEffect(() => {
        prevSavedRef.current = !!initialTopicData
    }, [initialTopicData])

    const onSubmit = useCallback(async ({ isCompleted } : { isCompleted: boolean }) => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled) return
        setSubmitting(true)
        
        try {
            await saveTopic({ topicId, studentAnswers: studentAnswers, submissionId, startTime: startTimeRef.current, isCompleted: isCompleted, prevSavedRef })
            setInitialStudentAnswers(studentAnswers)
            if (isCompleted) {
                toast.success("Topic Submitted.")
            }
            setSubmitting(false)
        } catch (err) {
            setSubmitting(false)
            toast.error(err?.response?.data?.message ?? "There was an error submitting the lesson. Please try again.")
        }
    }, [authContext.isLoggedIn, authContext.trackingEnabled, isTeacher, studentAnswers, topicId, submissionId])

    const onSubmitAssessment = useCallback(async () => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled || !assessmentSection || !assessment) return
    
        const form = document.getElementById("assessment-form") as HTMLFormElement
        const postParams = {...parseFormData(form, false), ...assessmentDrawResponses, startTime: startTimeRef.current}
        await submitAssessment({
            moduleName: assessment?.module,
            assessmentType: assessment?.type as Type,
            assessmentNumber: assessment?.number,
            submissionId: submissionId,
            postParams: postParams
        })
    }, [assessment, assessmentDrawResponses, assessmentSection, authContext.isLoggedIn, authContext.trackingEnabled, isTeacher, submissionId])

    const onSubmitLesson = useCallback(async () => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled || !lessonSection || !lesson) return

        await saveGuidedLesson({lesson: lesson, completed: true, isTeacher: false, lessonFormRef: topicFormRef,
            submissionId: submissionId, drawingResponses: lessonDrawResponses, allowEmpty: true })
    }, [authContext.isLoggedIn, authContext.trackingEnabled, isTeacher, lesson, lessonDrawResponses, submissionId, lessonSection])

    /**
     * Save the topic responses when click on "Save and Return to Assignments"
     */
    useEffect(() => {
        if (setOnReturn) {
            setOnReturn(() => {
                return () => {
                    const topicPromise = saveTopic({ topicId, studentAnswers: studentAnswers, submissionId, startTime: startTimeRef.current, isCompleted: false, prevSavedRef })
                    const assessmentPromise = onSubmitAssessment()
                    const lessonPromise = onSubmitLesson()
                    Promise.all([topicPromise, assessmentPromise, lessonPromise])
                    .catch(err => {
                        toast.error(err?.response?.data?.message ?? "There was an error auto saving the topic assignment. Please try again.")
                        return false 
                    })
                }
            })
        }
    }, [setOnReturn, studentAnswers, submissionId, topicId, onSubmitAssessment, assessmentSection, assessment, onSubmitLesson, lessonSection, lesson])

    /**
     * Update section progress bar status as student goes through the assignment
     */
    useEffect(() => {
        if (!currentSection || sectionHeaders.length === 0 || (isTeacher && !demoMode)) return

        if (assessmentSection) {
            const form = document.getElementById("assessment-form") as HTMLFormElement
            const [isComplete] = checkIfAssessmentComplete(form, Object.keys(assessmentDrawResponses).length)
            return setSectionHeaders(prev => prev.map(header => header.sectionNumber === currentSection.sortOrder ? {...header, progress : isComplete ? "complete" : "incomplete"} : header))
        } else if (lessonSection) {
            const parsedFormData = parseFormData(topicFormRef.current, true)
            const formHasData = formHasSufficientData(parsedFormData, lessonDrawResponses)
            return setSectionHeaders(prev => prev.map(header => header.sectionNumber === currentSection.sortOrder ? {...header, progress: formHasData ? "complete" : "incomplete"} : header))
        }

        const sectionProgress = checkSectionProgress({studentAnswers: studentAnswers, currentSectionNumber: currentSection.sortOrder, sectionType: currentSection.type, isGradable: currentSection.isGradable})
        const completedQuestionsNum = countCompletedQuestionsInSection({studentAnswers: studentAnswers, currentSectionNumber: currentSection.sortOrder})
        return setSectionHeaders(prev => prev.map(header => header.sectionNumber === currentSection.sortOrder ? {...header, progress: sectionProgress, completedQuestions: completedQuestionsNum} : header))
    }, [currentSection, currentSectionNumber, studentAnswers, isTeacher, assessmentSection, lessonSection, lessonDrawResponses, assessmentDrawResponses, refreshProgressCheck, sectionHeaders.length, demoMode])

    /**
     * Get Assessment for Explain! section
     */
    useEffect(() => {
        if (!assessmentSection) return
        (async () => {
            const assesssmentResponse = await assessments.findById({ id: currentSection.assessmentId })
            setAssessment(assesssmentResponse)
        })()
    }, [currentSection, assessmentSection])

    /**
     * Get Lesson for Explore section
     */
    useEffect(() => {
        if (!lessonSection) return
        (async () => {
            const lessonResponse = await lessons.findById(currentSection.lessonId)
            setLesson(lessonResponse)
        })()
    }, [currentSection, lessonSection])

    /**
     * Load previous data for either lesson or assessment section
     */
    useEffect(() => {
        if (!lessonSection && !assessmentSection) return

        // For demo mode, load the lesson/assessment previous responses from local storage rather than the database
        if (demoMode) {
            if (lessonSection) {
                const [lessonData, drawingData] = loadInitialLessonDemoData()
                setPrevLessonData(lessonData)
                setPrevLessonDrawData(drawingData)
            } else if (assessmentSection) {
                const [assessmentData, drawingData] = loadInitialAssessmentDemoData()
                setPrevAssessmentData(assessmentData)
                setAssessmentDrawResponses(drawingData)
                setPrevAssessmentDrawData(drawingData)
            }
            return
        }

        const loadPrevData = async () => { 
            const submission = (await assignments.getSubmission({submissionId: submissionId}))?.data
            if (!submission) return

            if (assessmentSection && assessment) {
                const [assessmentData, drawingData] = await loadInitialAssessmentData(assessment?.id, submission.studentId, submission.id)
                setPrevAssessmentData(assessmentData)
                setAssessmentDrawResponses(drawingData)
                setPrevAssessmentDrawData(drawingData)
            } else if (lessonSection && lesson) {
                const [lessonData, drawingData] = await loadInitialLessonData(lesson?.moduleName, lesson?.type, lesson?.number, submission.studentLessonId)
                setPrevLessonData(lessonData)
                setPrevLessonDrawData(drawingData)
            }
        }
        loadPrevData()
    }, [submissionId, lessonSection, assessmentSection, assessment?.id, lesson, assessment, demoMode])

    const nextSection = useCallback(async () => {
        if (assessmentSection) {
            await onSubmitAssessment()
        } else if (lessonSection) {
            await onSubmitLesson()
        } else {
            await onSubmit({isCompleted: false})
        }

        if (lastSection) {
            await onSubmit({isCompleted: true})
            return setActivePart("completed")
        }
        setCurrentSectionNumber(currentSectionNumber + 1)
        scrollRef.current?.scrollTo(0, 0)

        if (demoMode) setInitialStudentAnswers(studentAnswers)
    }, [currentSectionNumber, lastSection, lessonSection, assessmentSection, onSubmit, onSubmitAssessment, onSubmitLesson, setActivePart, demoMode, studentAnswers])

    const prevSection = useCallback(async () => {
        if (assessmentSection) {
            await onSubmitAssessment()
        } else if (lessonSection) {
            await onSubmitLesson()
        } else {
            await onSubmit({isCompleted: false})
        }
        setCurrentSectionNumber(currentSectionNumber - 1)
        scrollRef.current?.scrollTo(0, 0)

        if (demoMode) setInitialStudentAnswers(studentAnswers)
    }, [assessmentSection, lessonSection, currentSectionNumber, onSubmit, onSubmitAssessment, onSubmitLesson, demoMode, studentAnswers])

    const allSectionsComplete = useCallback(() => {
        if (isTeacher) return true
        return sectionHeaders.every(header => header.type === "HTML" || header.progress === "complete")
    }, [sectionHeaders, isTeacher])

    const shouldShowComfirmModal = useCallback(() => {
        if (isTeacher && !demoMode) return false

        if (assessmentSection) {
            const form = document.getElementById("assessment-form") as HTMLFormElement
            const [isComplete] = checkIfAssessmentComplete(form, Object.keys(assessmentDrawResponses).length)
            if (requireCompletion && !isComplete) return true
        } else if (lessonSection) {
            const parsedFormData = parseFormData(topicFormRef.current, true)
            const formHasData = formHasSufficientData(parsedFormData, lessonDrawResponses)
            if (requireCompletion && !formHasData) {
                highlightIncompleteLessonQuestions({...parsedFormData, ...lessonDrawResponses})
                return true
            }
        }
    
        const sectionProgress = checkSectionProgress({studentAnswers: studentAnswers, currentSectionNumber: currentSection.sortOrder, sectionType: currentSection.type, isGradable: currentSection.isGradable})
        if (requireCompletion && sectionProgress !== "complete") {
            highlightIncompleteTopicQuestions(currentSection, studentAnswers)
            return true
        }
        return false
    }, [assessmentDrawResponses, assessmentSection, currentSection, isTeacher, lessonDrawResponses, lessonSection, requireCompletion, demoMode, studentAnswers])

    // Handle multiple choice and text questions
    const handleAnswerChange = useCallback((sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => {
        const key = `s${sectionNum}-q${questionsNum}`
        
        setStudentAnswers(prev => ({
            ...prev, 
            [key]: {...prev[key], answer: answer, drawingResponse: drawingResponse}
        }))
        
        if (answer || drawingResponse) {
            removeHighlight(key)
        }
    }, [])

    const debouncedSaveTopic = useCallback(async () => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled) return

        const now = new Date()
        if (now.getTime() - saveTopicTimerRef.current.getTime() < saveLessonInterval) {
            return
        }
        saveTopicTimerRef.current = now

        saveTopic({ topicId, studentAnswers: studentAnswers, submissionId, startTime: startTimeRef.current, isCompleted: false, prevSavedRef })
            .catch(err => {
                toast.error(err?.response?.data?.message ?? "There was an error auto saving the topic assignment. Please try again.")
                return false 
            })
    }, [authContext.isLoggedIn, authContext.trackingEnabled, isTeacher, prevSavedRef, submissionId, studentAnswers, topicId])

    useEffect(() => {
        debouncedSaveTopic()
    }, [debouncedSaveTopic])

    const jumpToSection = useCallback(async (sectionNumber: number) => {
        setNextSectionLoading(true)
        if (assessmentSection) {
            await onSubmitAssessment()
        } else if (lessonSection) {
            await onSubmitLesson()
        } else {
            await onSubmit({isCompleted: false})
        }
        setCurrentSectionNumber(sectionNumber)
        setNextSectionLoading(false)

        if (demoMode) setInitialStudentAnswers(studentAnswers)
    }, [lessonSection, assessmentSection, onSubmit, onSubmitAssessment, onSubmitLesson, demoMode, studentAnswers])

    if (nextSectionLoading) return <div className="d-flex align-items-center justify-content-center vh-100"><BeatLoader /></div>

    return (<>
        <Modal show={showCompleteConfirmModal} centered size="lg" style={{ zIndex: 1061 }}>
            <Modal.Header>
                <Modal.Title className="w-100">{lastSection ? `Finish Topic Assignment` : `Submit ${currentSection?.header}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{lastSection ? "Some sections are incomplete. Are you sure you want to submit this topic assignment without completing them?" :
                    "You're still missing some questions. Are you sure you want to submit this section without completing them?"}</p>
                {lastSection && <p className="text-muted">Incomplete sections: {sectionHeaders?.filter(header => header.type !== "HTML" && header.progress !== "complete").map(header => header.header).join(", ")}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={() => setShowCompleteConfirmModal(false)}>No</Button>
                <Button 
                    variant="secondary" 
                    onClick={() => {
                        nextSection()
                        setShowCompleteConfirmModal(false)
                    }}>
                        Yes
                    </Button>
            </Modal.Footer>
        </Modal>

            <CustomModal
                showModal={showSectionSwitchConfirm}
                header="Incomplete Section"
                body="Are you sure that you want to leave this section without completing?"
                onConfirm={async () => {
                    await jumpToSection(destinedSection)
                    setShowSectionSwitchConfirm(false)
                }}
                onDecline={() => setShowSectionSwitchConfirm(false)}
                confirmBtnText="Yes"
                declineBtnText="No"
            />

            <Row className={`${styles.assignmentContents}`} ref={scrollRef}>
                <Form className={`${styles.lessonTabs} h-100`}>
                    {!lessonSection && !assessmentSection && 
                        <TopicSection
                            currentSection={currentSection}
                            studentAnswers={studentAnswers}
                            initialStudentAnswers={initialStudentAnswers}
                            setStudentAnswers={setStudentAnswers}
                            handleAnswerChange={handleAnswerChange}
                        />}
                    {assessmentSection && assessment &&
                        <AssignmentTopicContext.Provider
                            value={{assessmentDrawResponses, setAssessmentDrawResponses, refreshTopicSectionProgressCheck: toggleRefreshProgressCheck}}>
                            <AssignmentAssessment
                                isPartOfTopic={true}
                                initialAssessmentData={prevAssessmentData}
                                initialAssessmentDrawingData={prevAssessmentDrawData}
                                assessmentId={assessment?.id}
                                submissionId={submissionId}
                                moduleName={assessment?.module}
                                requireCompletion={requireCompletion}
                                assessmentNumber={assessment?.number}
                                type={assessment?.type as Type}
                                submitted={null}
                            />
                        </AssignmentTopicContext.Provider>}
                    {lessonSection && lesson &&
                        <AssignmentTopicContext.Provider
                            value={{lessonDrawResponses, setLessonDrawResponses, refreshTopicSectionProgressCheck: toggleRefreshProgressCheck}}>
                            <AssignmentLesson
                                topicLessonId={lesson.id}
                                submissionId={submissionId}
                                moduleName={lesson.moduleName}
                                type={lesson.type}
                                videoId={activeVideoId}
                                hasAssessment={false}
                                initialLessonData={prevLessonData}
                                initialDrawingData={prevLessonDrawData}
                                initialPureInquiryData={null}
                                lessonVariety={"Guided"}
                                preSubmit={null}
                                isPartOfTopic={true}
                                topicFormRef={topicFormRef}
                            />
                        </AssignmentTopicContext.Provider>}
                </Form>
            </Row>
            <Row className={`${styles.assignmentProgress} my-3`}>
                <Col sm={12} xl={8} className={`${styles.fitContent} m-auto`}>
                    <AssignmentTopicProgress 
                        currentSectionNumber={currentSectionNumber}
                        sectionHeaders={sectionHeaders}
                        jumpToSection={async (sectionNumber) => {
                            const showModal = shouldShowComfirmModal()
                            if (showModal && sectionNumber !== currentSectionNumber) {
                                setDestinedSection(sectionNumber)
                                return setShowSectionSwitchConfirm(true)
                            }
                            await jumpToSection(sectionNumber)
                        }} />
                </Col>
                <Col sm={12} xl={4} className={`text-end ${styles.fitContent} d-flex justify-content-center`}>
                    <Button
                        variant="outline-theme"
                        className={`${styles.assignmentActionButtons} me-3`}
                        disabled={currentSectionNumber === 0}
                        onClick={prevSection}>
                            Previous
                    </Button>
                    <Button
                        disabled={submitting}
                        variant="outline-theme"
                        className={`${styles.assignmentActionButtons} me-3`}
                        onClick={() => {
                            if (lastSection) {
                                const allIsComplete = allSectionsComplete()
                                if (requireCompletion && !allIsComplete) {
                                    highlightIncompleteTopicQuestions(currentSection, studentAnswers)
                                    return toast.error("Please complete all sections before submitting.")
                                }
                                return allIsComplete ? nextSection() : setShowCompleteConfirmModal(true)
                            }
                            const showModal = shouldShowComfirmModal()
                            return showModal ? setShowCompleteConfirmModal(true) : nextSection()
                        }}>
                            {(isTeacher && !demoMode) ? `Continue` : lastSection ? `Finish Assignment` : `Save and Continue`}
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default AssignmentTopic
