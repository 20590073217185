import { Button } from "react-bootstrap"

export const AddTopicButton: React.FC<{onClick: () => void}> = ({ onClick }) => {
    return (
        <Button
            key="add-topic-assignment-btn"
            className="ms-2"
            onClick={onClick}
            variant="theme">
                <i className="fas fa-plus pe-2" />
                <span className="d-none d-md-inline"><span className="d-none d-lg-inline">Textbook </span><span>Topic</span></span>
        </Button>
    )
}
