import { CurriculumSectionType, TopicStudentAnswers } from "shared/types/curriculumTypes"
import { checkIfAssessmentSubmissionIsComplete } from "./assessmentHelper"
import { SectionHeader, SectionProgressType } from "../AssignmentTopic"

export const sanitizeSectionHeader = (header: string) => {
    return (header === "" || header === null) ? "Other" : header
}

export const shortenSectionHeader = (header: string, num: number) => {
    const noSpaceHeader = header?.split(" ")
    return noSpaceHeader?.length > 1 ? num.toString() : header
}

interface CheckSectionProgressProps {
    studentAnswers: TopicStudentAnswers
    currentSectionNumber: number
    sectionType: CurriculumSectionType
    isGradable: boolean
}

/**
 * Checks gradable topic section (that contains questions) progress and return one of three values - not-visited, incomplete, complete.
 * Mark the section as complete if the section is HTML or not gradable.
 */
export const checkSectionProgress = ({studentAnswers, currentSectionNumber, sectionType, isGradable} : CheckSectionProgressProps): SectionProgressType => {
    if (sectionType !== "Questions" || !isGradable) return "complete"

    const questionRegex = new RegExp(`^s${currentSectionNumber}-q\\d+$`)
    const tableRegex = new RegExp(`^s${currentSectionNumber}-t\\d+$`)

    const questionsExist = Object.keys(studentAnswers).reduce((acc, key) => {
        if (questionRegex.test(key)) {
            acc = true
        }
        return acc
    }, false)

    const sectionTableExists = Object.keys(studentAnswers).reduce((acc, key) => {
        if (tableRegex.test(key) && studentAnswers[key]?.questionNumber === null) {
            acc = true
        }
        return acc
    }, false)

    const tableHasCheckboxesOnly = Object.keys(studentAnswers).every((key) => {
        return !tableRegex.test(key) || studentAnswers[key]?.isCheckbox
    })

    let allQuestionsHaveValue = true
    let someQuestionsHaveValue = false
    let questionsHaveEmptyValue = false
    let tableHasValue = false

    // Return `complete` if all responses have values, return `incomplete` if some responses have values, return `not-visited` if none of the responses have values
    for (const key of Object.keys(studentAnswers)) {
        const isRegularQuestion = questionRegex.test(key) && (studentAnswers[key].questionType === "Text" || studentAnswers[key].questionType === "MultipleChoice")
        const isDrawingQuestion = questionRegex.test(key) && studentAnswers[key].questionType === "Drawing"
        const isTableQuestion = questionRegex.test(key) && studentAnswers[key].questionType === "Table"
        const isSectionTable = tableRegex.test(key) && studentAnswers[key].questionNumber === null

        if ([isRegularQuestion, isDrawingQuestion, isTableQuestion, isSectionTable].every(a => !a)) continue

        let questionResponseHasValue = false
        let tableResponseHasValue = false

        if (isRegularQuestion) {
            questionResponseHasValue = !!studentAnswers[key].answer
        } else if (isDrawingQuestion) {
            questionResponseHasValue = !!studentAnswers[key].drawingResponse
        } else if (isTableQuestion) {
            questionResponseHasValue = tableQuestionIsComplete({studentAnswers, currentSectionNumber, questionNumber: parseInt(key.split("-")[1].replace("q", "")), checkboxOnly: tableHasCheckboxesOnly})
        } else if (isSectionTable) {
            tableResponseHasValue = !!(studentAnswers[key].answer || studentAnswers[key].drawingResponse)
        }

        if ((isRegularQuestion || isDrawingQuestion || isTableQuestion) && !questionResponseHasValue) {
            allQuestionsHaveValue = false
            questionsHaveEmptyValue = true
        }

        if ((isRegularQuestion || isDrawingQuestion || isTableQuestion) && questionResponseHasValue) {
            someQuestionsHaveValue = true
        }

        if (isSectionTable && tableResponseHasValue) {
            tableHasValue = true
        }
    }

    // Handle all three conditions - section has both questions and tables, section only has questions, section only has tables
    if (questionsExist && sectionTableExists) {
        return (allQuestionsHaveValue && tableHasValue) ? "complete" : (!someQuestionsHaveValue && questionsHaveEmptyValue && !tableHasValue) ? "not-visited" : "incomplete"
    } else if (questionsExist && !sectionTableExists) {
        return allQuestionsHaveValue ? "complete" : (someQuestionsHaveValue && questionsHaveEmptyValue) ? "incomplete" : "not-visited"
    } else if (!questionsExist && sectionTableExists) {
        return (tableHasValue || tableHasCheckboxesOnly) ? "complete" : "not-visited"
    } else {
        return "not-visited"
    }
}

export const countCompletedQuestionsInSection = ({ studentAnswers, currentSectionNumber } : {studentAnswers: TopicStudentAnswers, currentSectionNumber: number}) => {
    const questionRegex = new RegExp(`^s${currentSectionNumber}-(q)\\d+$`)
    const tableRegex = new RegExp(`^s${currentSectionNumber}-(t)\\d+$`)

    const questionsCompletedCount =  Object.keys(studentAnswers).reduce((acc, key) => {
        if (!questionRegex.test(key) || studentAnswers[key]?.isCheckbox) return acc
        
        if ((studentAnswers[key].questionType === "Drawing")) {
            acc += studentAnswers[key].drawingResponse ? 1 : 0
        } else {
            acc += studentAnswers[key].answer ? 1 : 0
        }
        return acc
    }, 0)

    const completedTableQuestions = new Set<number>()

    const tableCompletedCount =  Object.keys(studentAnswers).reduce((acc, key) => {
        if (!tableRegex.test(key)) return acc

        const tableAnswer = studentAnswers[key].answer
        const tableQuestionNumber = studentAnswers[key].questionNumber // only table question inputs have question number
        const isCheckbox = studentAnswers[key]?.isCheckbox
        
        if (tableQuestionNumber && tableAnswer && !completedTableQuestions.has(tableQuestionNumber)) {
            // increment only once for each table question
            acc += 1
            completedTableQuestions.add(tableQuestionNumber)
        } else if (!tableQuestionNumber && !isCheckbox && tableAnswer) {
            // increment every input that has value for section tables
            acc += 1
        }
        return acc
    }, 0)

    return questionsCompletedCount + tableCompletedCount
}

// Check all the table responses that are associated with a table question number. Return true if any of the table responses have a value.
const tableQuestionIsComplete = ({ studentAnswers, currentSectionNumber, questionNumber, checkboxOnly } : { studentAnswers: TopicStudentAnswers, currentSectionNumber: number, questionNumber: number, checkboxOnly: boolean }) => {
    const tableRegex = new RegExp(`^s${currentSectionNumber}-(t)\\d+$`)

    return Object.keys(studentAnswers).reduce((acc, key) => {
        if (!tableRegex.test(key) || studentAnswers[key]?.questionNumber !== questionNumber) return acc

        if (studentAnswers[key].answer || (checkboxOnly && studentAnswers[key]?.isCheckbox)) {
            acc = true
        }
        return acc
    }, false)
}

const checkIfLessonSubmissionIsComplete = (lessonSubmission: Record<string, string>) : SectionProgressType => {
    if (!lessonSubmission) return

    let hasValue = false
    let hasEmptyValue = false

    for (let key of Object.keys(lessonSubmission)) {
        if (lessonSubmission[key]) {
            hasValue = true
        } else {
            hasEmptyValue = true
        }

        if (hasValue && hasEmptyValue) {
            return "incomplete"
        }
    }
    return hasValue ? "complete" : "not-visited"
}

export const updateSectionsProgress = ({initialTopicData, initialAssessmentData, initialLessonData, headers} : {initialTopicData: TopicStudentAnswers, initialAssessmentData: Record<string, string>, initialLessonData: Record<string, string>, headers: SectionHeader[]}) => {
    let newHeaders = [...headers]

    const assessmentSubmissionProgress = checkIfAssessmentSubmissionIsComplete(initialAssessmentData)
    const lessonSubmissionProgress = checkIfLessonSubmissionIsComplete(initialLessonData)

    const sectionNumbers: number[] = Object.keys(initialTopicData).reduce((acc, curr) => {
        const sectionNumber = parseInt(curr.split("-")[0][1]) // get section number from format - s1-q1 or s1-t1
        if (!acc.includes(sectionNumber)) acc.push(sectionNumber)
        return acc
    }, [])

    // Update lesson and assessment sections progress
    newHeaders = newHeaders.map(header => {
        if (header.type === "Lesson") {
            return { ...header, progress: lessonSubmissionProgress } as SectionHeader
        } else if (header.type === "Assessment") {
            return { ...header, progress: assessmentSubmissionProgress } as SectionHeader
        } else {
            return header
        }
    })

    // Update non-HTML, non-Lesson, non-Assessment sections progress
    for (const sectionNumber of sectionNumbers) {
        const sectionProgress = checkSectionProgress({studentAnswers: initialTopicData, currentSectionNumber: sectionNumber, sectionType: "Questions", isGradable: true})
        const completedQuestionsNum = countCompletedQuestionsInSection({studentAnswers: initialTopicData, currentSectionNumber: sectionNumber})
        newHeaders = newHeaders.map(header => {
            if (header.type === "Questions" && header.sectionNumber === sectionNumber) {
                // When resume assignment, table checkboxes are also included in completed count so Math.min is used to ensure that completed count does not exceed total questions count
                return { ...header, progress: sectionProgress, completedQuestions: Math.min(completedQuestionsNum, header.totalQuestions) } as SectionHeader
            } else {
                return header
            }
        })
    }

    // Update HTML section progress
    newHeaders = newHeaders.map((header, index) => {
        if (header.type === "HTML") {
            // If the any of the sections that come next from this point on has already been visited, mark this HTML section completed
            for (let i = index + 1; i < newHeaders.length; i++) {
                if (newHeaders[i].progress !== "not-visited") {
                    return { ...header, progress: "complete" } as SectionHeader
                }
            }
            return header
        } else {
            return header
        }
    })
    
    return newHeaders 
}
