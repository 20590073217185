import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import Table from "components/Lessons/PureInquiry/Table"
import styles from "../studentSubmission.module.scss"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    expand: boolean
}

const StudentLessonTableResponses = ({ lessonSubmission, expand } : Props) => {
    if ((!lessonSubmission?.tableHtml && !lessonSubmission?.tableResponses) || !expand) return <></>

    return <div className="px-5">
        {lessonSubmission.tableHtml && <>
            <link
                rel={"stylesheet"}
                type={"text/css"}
                href={lessonSubmission.tableCssPath}
            />
            <link
                rel={"stylesheet"}
                type={"text/css"}
                href={"/styles/lesson.css?version=3-2-18"}
            />
            <script type={"text/javascript"} src="/scripts/lessons/data-tables.js" />
            <div
                className={"lessonTable"}
                id={"table-data"}
                data-info={lessonSubmission.tableData}
                dangerouslySetInnerHTML={{ __html: lessonSubmission.tableHtml }}
            />

        </>}

        {lessonSubmission.tableResponses && <>
            <Table rowCount={10} columnCount={6} values={lessonSubmission.tableResponses} />
        </>}
        <br />
        <p className="mb-2"><strong>Grade</strong></p>
        
        {lessonSubmission.status === "rework-required" ? "---" : `${lessonSubmission.tableGrade ?? 0} / 10`}
        <br />
        <p className="mb-2"><strong>Comments</strong></p>
        <br />
        <textarea
            className={`${styles.commentTextbox} w-100`}
            disabled={true}
            defaultValue={lessonSubmission.tableComment ?? ""}
        />
    </div>
}

export default StudentLessonTableResponses
