import { useEffect, useState } from "react"
import { AndThenStandard, ClassResponse, SaqInfoResponse } from "../../../shared/types/teacherTypes"
import * as teacher from "../../../shared/routes/teacher"
import BeatLoader from "react-spinners/BeatLoader"
import { useNavigate } from "react-router-dom"
import modules from "shared/routes/moduleRoutes"
import { toast } from "react-toastify"
import { Button, Table, Modal, ButtonGroup, Col, Row } from "react-bootstrap"
import lessonPlans from "shared/routes/lessonPlans"
import lessons, { LessonResponse } from "shared/lessons"
import ToolTip from "components/General/ToolTip"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

type SAQParams = {
    activeClass: ClassResponse
}

function MasteryBar({ totalStudents, results }: { totalStudents: number, results: { mastery: string, students: string[] }[] }) {
    const masteryMap = {
        'Not Mastered': '#F44336',          // Red
        'Approaches Mastery': '#FF9800',    // Orange
        'Mastered': '#4CAF50',              // Green
        'Exceeds Mastery': '#0000FF'        // Blue
    }

    return (
        <div className="d-flex align-items-center">
            <div style={{ 
                width: '100%',
                height: '20px',
                backgroundColor: '#E0E0E0',
                borderRadius: '2px',
                overflow: 'hidden'
            }}>
                {Object.entries(masteryMap).map(([masteryLevel, color]) => {
                    const result = results.find((r: any) => r.mastery === masteryLevel)
                    const width = result ? (result.students.length / totalStudents) * 100 : 0
                    
                    return (
                        <ToolTip title={`${masteryLevel}: ${result?.students.length}`} key={result?.mastery + masteryLevel}>
                            <span 
                                style={{ 
                                display: 'inline-block',
                                width: `${width}%`,
                                height: '100%',
                                backgroundColor: color
                            }}
                        />
                        </ToolTip>
                    );
                })}
            </div>
        </div>
    );
}

export default function SAQLandingPage({ activeClass }: SAQParams) {
    const [saqInfo, setSaqInfo] = useState<SaqInfoResponse | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [totalStudents, setTotalStudents] = useState(1)
    const [showStandardModal, setShowStandardModal] = useState(false)
    const [selectedStandard, setSelectedStandard] = useState<AndThenStandard | false>(false)
    const [activeTab, setActiveTab] = useState<'assignments' | 'standards'>(null)

    // Redirect if this is not a valid class
    const navigate = useNavigate()
    useEffect(() => {
        if (!activeClass || activeClass.gradeLevel !== "5th Grade" || !modules.checkModuleAccess("SAQ: Grade 5")) {
            navigate("/dashboard/teacher/overview")
        }
    }, [activeClass, navigate])

    // Get SAQ information
    useEffect(() => {
        teacher.getSaqInfo({ classId: activeClass.id }).then((response) => {
            if (response) {
                if (activeTab == null) {
                    if ( response?.saqTwoAssigned) {
                        setActiveTab('assignments')
                    } else {
                        setActiveTab('standards')
                    }
                }
                setSaqInfo(response)
                setLoading(false)
            } else {
                setError(true)
                setLoading(false)
            }
        })
    }, [activeClass, activeTab])

    useEffect(() => {
        teacher.getStudents({ classID: activeClass.id }).then((response) => {
            setTotalStudents(response.length ?? 1)
        })
    }, [activeClass])

    async function handleAssignModule(module: string) {
        // Check if the SAQ Lesson Plan already exists
        const planExists = (await lessonPlans.getLessonPlans()).find((plan) => plan.planName === module)
        if (planExists) {
            toast.info("SAQ Lesson Plan already exists.")
            navigate(`/dashboard/teacher/lesson-plans/${planExists.lessonPlanId}`)
            return
        }

        // Create a new lesson plan to assign the SAQ assessments
        await lessonPlans.createLessonPlan({
            name: module,
            description: module
        })

        const newPlan = (await lessonPlans.getLessonPlans()).find((plan) => plan.planName === module)
        if (!newPlan) {
            toast.error("Error: Failed to create SAQ Lesson Plan. Please try again later.")
            return
        }
        
        // List of SAQ assessments
        const moduleAssessments = (await lessons.getModuleLessons(module)).filter((lesson) => lesson.assessment)
        if (moduleAssessments.length === 0) {
            toast.error("Error: No SAQ assessments found")
            return
        }

        // Create a new plan assignment for each of the SAQ assessments
        for (const assessment of moduleAssessments) {
            await lessonPlans.createPlanAssignment({
                planId: newPlan.lessonPlanId,
                assessmentId: assessment.assessment.id
            })
        }

        // Navigate to the new lesson plan
        navigate(`/dashboard/teacher/lesson-plans/${newPlan.lessonPlanId}`)

    }

    function handleRecommendationClick(recommendation: LessonResponse) {
        // Go to assignment
        navigate("/dashboard/teacher/assignments/add", {
            state: {
                lessonId: recommendation.id,
                lessonVariety: recommendation.variety,
                module: recommendation.moduleName,
                type: recommendation.type,
                assessment: recommendation.assessment,
                assignmentType: "lesson"
            }
        })
    }

    function handleStandardClick(standard: any) {
        setSelectedStandard(standard)
        setShowStandardModal(true)
    }

    function saqSummaryStats(studentCount: number, saqInfo: SaqInfoResponse) {
        // Filter and group assignments by unit number (1-4)
        const unitsOne = saqInfo.saqOneAssignments
            .filter(assignment => /Unit (1|2|3|4)/i.test(assignment.title))
            .reduce((acc, assignment) => {
                const unitNumber = assignment.title.match(/Unit (1|2|3|4)/i)?.[1] as string
                if (!acc[unitNumber]) acc[unitNumber] = []
                acc[unitNumber].push(assignment)
                return acc
            }, {} as { [key: string]: typeof saqInfo.saqOneAssignments })

        const unitsTwo = saqInfo.saqTwoAssignments
            .filter(assignment => /Unit (1|2|3|4)/i.test(assignment.title))
            .reduce((acc, assignment) => {
                const unitNumber = assignment.title.match(/Unit (1|2|3|4)/i)?.[1] as string
                if (!acc[unitNumber]) acc[unitNumber] = []
                acc[unitNumber].push(assignment)
                return acc
            }, {} as { [key: string]: typeof saqInfo.saqTwoAssignments })

        // Transform data for chart
        const chartData = Object.keys(unitsOne).map((unit) => {
            const unitOneData = unitsOne[unit]
            const unitTwoData = unitsTwo[unit]
            
            // Handle cases where there are no submissions
            const totalSaqOneSubmissions = unitOneData ? unitOneData.reduce((acc, curr) => acc + curr.submissionCount, 0) : 0
            const totalSaqTwoSubmissions = unitTwoData ? unitTwoData.reduce((acc, curr) => acc + curr.submissionCount, 0) : 0

            // Only calculate averages if there are submissions
            const totalSaqOneGradeAverage = totalSaqOneSubmissions > 0
                ? unitOneData.reduce((acc, curr) => acc + (curr.gradeAverage * curr.submissionCount), 0) / totalSaqOneSubmissions
                : 0
            const totalSaqTwoGradeAverage = totalSaqTwoSubmissions > 0
                ? unitTwoData.reduce((acc, curr) => acc + (curr.gradeAverage * curr.submissionCount), 0) / totalSaqTwoSubmissions
                : 0

            const saqOneCompletionRate = unitOneData && unitOneData.length > 0 
                ? (totalSaqOneSubmissions / (unitOneData.length * studentCount)) * 100 
                : 0
            const saqTwoCompletionRate = unitTwoData && unitTwoData.length > 0
                ? (totalSaqTwoSubmissions / (unitTwoData.length * studentCount)) * 100
                : 0

            return {
                unit: `Unit ${unit}`,
                saqOneAverage: Number(totalSaqOneGradeAverage.toFixed(0)),
                saqTwoAverage: Number(totalSaqTwoGradeAverage.toFixed(0)),
                saqOneCompletionRate: Number(saqOneCompletionRate.toFixed(0)),
                saqTwoCompletionRate: Number(saqTwoCompletionRate.toFixed(0))
            }
        })

        return (
            <div className="mb-3">
                <div className="p-2 rounded">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="category" dataKey="unit" />
                            <YAxis domain={[0, 100]} label={{ value: 'Percent', angle: -90, position: 'insideLeft' }} />
                            <Tooltip />
                            <Bar dataKey="saqOneCompletionRate" name="SAQ 1 Completion Rate" fill="rgba(255, 165, 0, 0.5)" barSize={40} />
                            <Bar dataKey="saqOneAverage" name="SAQ 1 Average" fill="#FF9800" barSize={40} />
                            <Bar dataKey="saqTwoAverage" name="SAQ 2 Average" fill="#4CAF50" barSize={40} />
                            <Bar dataKey="saqTwoCompletionRate" name="SAQ 2 Completion Rate" fill="rgba(76, 175, 80, 0.5)" barSize={40} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )
    }

    return (
        <>
        {loading ? (
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <h1>Getting your class data <BeatLoader /></h1>
            </div>
        ) : error ? (
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <h1>Error getting SAQ information</h1>
                <p>Please try again later.</p>
            </div>
        ) : (
            <div className="p-3">
                {!saqInfo?.saqOneAssigned ? (
                    <div className="text-start">
                        <h3>Get Started With SAQ</h3>
                        <p>
                            SAQ is a diagnostic assessment that helps you understand your students' strengths and weaknesses, as well as prepare them for end of year exams. Once assigned, you will be able to view the results and use the data to address gaps in student knowledge and follow up to see their progress.
                        </p>
                        <p>
                            The button below will get you started scheduling the SAQ for your class.
                        </p>
                        <p>
                            This will create a new lesson plan, which you can use to schedule the SAQ assessments for your class.
                        </p>
                        <Button className="mt-3" variant="primary" onClick={() => handleAssignModule("SAQ: Grade 5")}>
                            Assign SAQ
                        </Button>
                    </div>
                ) : (
                    <div className="text-start">
                        <h3>Class SAQ Progress</h3>
                        <p>
                            You have assigned the SAQ assessments to your class. You can view the results and use the data to address gaps in student knowledge and follow up to see their progress.
                        </p>
                        {!saqInfo.saqTwoAssigned && (
                            <div className="mb-3">
                                <p>
                                    You have not assigned the SAQ 2 assessments to your class yet. We recommend assigning the SAQ 2 assessments to your class after you review their results and assign remediation lessons. When you are ready to assign the SAQ 2 assessments, you can use the button below.
                                </p>
                                <Button variant="primary" className="me-3" onClick={() => handleAssignModule("SAQ: Grade 5 Retest")}>
                                    Assign SAQ 2
                                </Button>
                                <Button variant="outline-primary" onClick={() => handleAssignModule("SAQ: Grade 5")}>
                                    Reassign SAQ 1
                                </Button>
                            </div>
                        )}
                        <hr />
                        <div className="mb-3">
                            <h3 className="mb-3" >Results</h3>
                            <ButtonGroup>
                                <Button 
                                    variant="outline-primary"
                                    className={activeTab === 'assignments' ? 'active' : ''}
                                    onClick={() => setActiveTab('assignments')}
                                >
                                    Assignment Grades
                                </Button>
                                <Button 
                                    variant="outline-primary"
                                    className={activeTab === 'standards' ? 'active' : ''}
                                    onClick={() => setActiveTab('standards')}
                                >
                                    Standards Mastery
                                </Button>
                            </ButtonGroup>
                        </div>

                        {activeTab === 'assignments' ? (
                            saqSummaryStats(totalStudents, saqInfo)
                        ) : (
                            <div>
                                {totalStudents > 0 ? ( <>
                                    <p>
                                        The table below shows the mastery results for each standard in the SAQ 1 assessments. Use these results to address gaps in student knowledge and follow up to see their progress on SAQ 2.
                                    </p>
                                    <p className="m-auto p-3 bg-light rounded text-center w-50 border border-primary">
                                        <i className="fas fa-arrow-down me-3"></i> Click on a standard to view the mastery results and remediation recommendations. <i className="fas fa-arrow-down ms-3"></i>
                                    </p>
                            
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Standard</th>
                                                <th>Mastery Results</th>
                                                <th>Standard Mastery</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { // Sort the rows by students who have not mastered the standard, and then alphabatically
                                            saqInfo.saqOneStandards.sort((a, b) => {
                                                const notMasteredA = a.masteryResults.reduce((acc, curr) => curr.mastery === "Not Mastered" || curr.mastery === "Approaches Mastery" ? acc + curr.students.length : acc, 0);
                                                const notMasteredB = b.masteryResults.reduce((acc, curr) => curr.mastery === "Not Mastered" || curr.mastery === "Approaches Mastery" ? acc + curr.students.length : acc, 0);
                                                return notMasteredB - notMasteredA || a.standard?.subtopicCode?.localeCompare(b.standard.subtopicCode);
                                            }).map((standard) => (
                                                <tr key={standard.standard?.subtopicId} onClick={() => handleStandardClick(standard)} role="button">
                                                    <td>{standard.standard?.subtopicCode}</td>
                                                    <td><MasteryBar totalStudents={totalStudents} results={standard.masteryResults} /></td>
                                                    <td>{
                                                        (standard.masteryResults.reduce((acc, curr) => {
                                                            return curr.mastery === "Mastered" || curr.mastery === "Exceeds Mastery" ? acc + curr.students.length : acc
                                                        }, 0) / (totalStudents ? totalStudents : 1) * 100
                                                        ).toFixed(0)}%</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <div className="mt-3 w-50">
                                        <h5>Legend:</h5>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <div className="d-flex align-items-center">
                                                    <span style={{ backgroundColor: '#F44336', width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                                    <span>Not Mastered</span>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <div className="d-flex align-items-center">
                                                    <span style={{ backgroundColor: '#FF9800', width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                                    <span>Approaches Mastery</span>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <div className="d-flex align-items-center">
                                                    <span style={{ backgroundColor: '#4CAF50', width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                                    <span>Mastered</span>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <div className="d-flex align-items-center">
                                                    <span style={{ backgroundColor: '#0000FF', width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></span>
                                                    <span>Exceeds Mastery</span>
                                                </div>
                                            </Col>
                                            </Row>
                                        </div>
                                </>
                                ) : (
                                    <>
                                        <h3 className="mt-3">
                                            No students have completed the SAQ 1 assessments yet.
                                        </h3>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )}

        {selectedStandard && (
            <Modal show={showStandardModal} onHide={() => setShowStandardModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Standard Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-start">
                    <h3>{selectedStandard?.standard?.subtopicCode}</h3>
                <p><strong>Description:</strong> {selectedStandard?.standard?.description}</p>
                <p><strong>Mastery Results:</strong>
                    <MasteryBar totalStudents={totalStudents} results={selectedStandard.masteryResults} />
                </p>
                <p><strong>Remediation {selectedStandard.remediationRecommendations.length > 1 ? "Recommendations" : "Recommendation"}:</strong></p>
                {selectedStandard.remediationRecommendations.map((recommendation) => (
                <p key={recommendation.id}><Button variant="primary" className="me-2" onClick={() => handleRecommendationClick(recommendation)}>Assign</Button> {recommendation.title}</p>
                ))}
                </Modal.Body>
            </Modal>
        )}
        </>
    )
}