import { Permission } from "models/auth"
import { createContext } from "react"
import { StudentResponse } from "shared/types/teacherTypes"

export type AuthContextType = {
    loading: boolean
    permissions: (Permission)[],
    isLoggedIn: boolean
    emailVerified: boolean
    isStudent: boolean
    studentInfo?: StudentResponse
    isAdmin: boolean
    isStaff: boolean
    trackingEnabled: boolean
    hasCurriculumAccess: boolean
    csrfToken: string
    triggerAuthRefresh: any
    impersonationMode: boolean  
}

export const AuthContext = createContext<AuthContextType>(({
    loading: true,
    isLoggedIn: false,
    emailVerified: false,
    isStudent: false,
    studentInfo: null,
    isAdmin: false,
    isStaff: false,
    trackingEnabled: false,
    csrfToken: "",
    permissions: [],
    hasCurriculumAccess: false,
    triggerAuthRefresh: null,
    impersonationMode: false,
}))

