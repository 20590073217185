import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { getSubtopics, StandardsReturn, Subtopic } from "models/categories"
import styles from "./editingStyles.module.scss"
import ToolTip from 'components/General/ToolTip'
import useBoolean from 'helpers/useBoolean'
import { addTopicQuestionStandard, deleteTopicQuestionStandard, getTopicQuestionStandards } from 'shared/routes/curriculum/books'
import { useModel } from '@stem-sims/nexus'
import { PulseLoader } from 'react-spinners'

interface Props {
    curriculumQuestionId: string
}

const TopicQuestionStandards = ({ curriculumQuestionId } : Props) => {

    const [showModal, toggleModal] = useBoolean(false)

    const [selectedStandard, setSelectedStandard] = useState<string | null>(null)
    const [selectedStandardTopic, setSelectedStandardTopic] = useState<string | null>(null)
    const [selectedStandardSubtopic, setSelectedStandardSubtopic] = useState<Subtopic | null>(null)
    const [core, setCore] = useState(false)

    const [availableStandards, setAvailableStandards] = useState<StandardsReturn[]>([])

    const { loading: standardsLoading, response: standards, refreshModel } = useModel({
        model: getTopicQuestionStandards,
        props: { curriculumQuestionId }
    })

    useEffect(() => {
        let standards: StandardsReturn[] = []
        getSubtopics().then(data => {
            standards = data
        }).finally(() => {
            setAvailableStandards(standards)
        })
    }, [])

    function selectStandard(standard: string) {
        setSelectedStandard(standard)
        setSelectedStandardTopic(null)
        setSelectedStandardSubtopic(null)
    }

    function selectStandardTopic(topic: string) {
        setSelectedStandardTopic(topic)
        setSelectedStandardSubtopic(null)
    }

    function hideModal () {
        toggleModal()
        setSelectedStandard(null)
        setSelectedStandardTopic(null)
        setSelectedStandardSubtopic(null)
        setCore(false)
    }

    const addStandardCallback = useCallback(async () => {
        await addTopicQuestionStandard({ curriculumQuestionId: curriculumQuestionId, subtopicId: selectedStandardSubtopic?.id, core: core })
        refreshModel()
        setSelectedStandard(null)
        setSelectedStandardTopic(null)
        setSelectedStandardSubtopic(null)
        setCore(false)
    }, [refreshModel, curriculumQuestionId, selectedStandardSubtopic, core]) 

    const deleteStandardCallback = useCallback(async (values) => {
        await deleteTopicQuestionStandard({ id: values.id })
        refreshModel()
    }, [refreshModel])

  return (
    <div>
        {standardsLoading && <>
            <PulseLoader />
        </>}
        {standards && <div className="my-3">
            {standards.length > 0 && <p>Standards</p>}
            {standards.map((standard) => (
                <div key={standard.id} className="mb-2 d-flex justify-content-between align-items-center">
                    <ToolTip title={standard.description}>
                        <span className="me-5 fw-bold">{standard.standard} - {standard.topicCode} - {standard.subtopicCode} {standard.core && <i className={`fas fa-star ${styles.star}`} />}</span>
                    </ToolTip>
                    <i className="fas fa-times text-danger" role="button" onClick={() => deleteStandardCallback(standard)} />
                </div>
            ))}
        </div>}
        <Button variant="primary" onClick={toggleModal}>Add Standards</Button>
        <Modal show={showModal} centered size="lg" onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title className="w-100 fw-bold">Add Standards</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className="p-2">
                    {standards?.length === 0 && <p className="text-muted">No current standards</p>}
                    {standards && standards.length > 0 && <ul className="list-group text-start">
                        <h5 className="fw-bold text-start">Current Standards:</h5>
                        {standards.map((standard) => (
                            <li key={standard.id} className="list-group-item">
                                <ToolTip title={standard.description}>
                                    <span className="fw-bold d-flex justify-content-between">
                                        <span>{standard.standard} - {standard.topicCode} - {standard.subtopicCode} {standard.core && <i className={`fas fa-star ${styles.star}`} />}</span>
                                        <i className="fas fa-times text-danger" role="button" onClick={() => deleteStandardCallback(standard)} />
                                    </span>
                                </ToolTip>
                            </li>
                        ))}
                    </ul>}
                </Row>
                <hr />
                <Row>
                    <h5 className="fw-bold text-start">Available Standards:</h5>
                    <Col>
                        <ul className="list-group">
                            {availableStandards.map(standard => (
                                <li key={standard.standard} className={`list-group-item ${styles.selectable} ${selectedStandard === standard.standard && "bg-primary text-white rounded"}`} onClick={() => selectStandard(standard.standard)}>{standard.standard}</li>
                            ))}
                        </ul>
                    </Col>
                    <Col>
                        <ul className="list-group">
                            {selectedStandard && availableStandards.find(standard => standard.standard === selectedStandard)?.topics.map(topic => (
                                <li key={topic.topic} className={`list-group-item ${styles.selectable} ${selectedStandardTopic === topic.topic && "bg-primary text-white rounded"}`} onClick={() => selectStandardTopic(topic.topic)}>{topic.topic}</li>
                            ))}
                        </ul>
                    </Col>
                    <Col>
                        <ul className="list-group">
                            {selectedStandardTopic && availableStandards.find(standard => standard.standard === selectedStandard)?.topics.find(topic => topic.topic === selectedStandardTopic)?.subtopics.map(subtopic => (
                                <li 
                                    key={subtopic.subtopic} 
                                    className={`list-group-item ${styles.selectable} ${selectedStandardSubtopic === subtopic && "bg-primary text-white rounded"}`} 
                                    onClick={() => setSelectedStandardSubtopic(subtopic)}>
                                        <ToolTip title={subtopic.description}>
                                            <div>{subtopic.subtopic}</div>
                                        </ToolTip>
                                </li>
                            ))}
                        </ul>
                        {selectedStandardSubtopic && 
                        <div className="mt-2">
                            <Form.Group className="my-2" controlId="question">
                                <Form.Label className="fw-bold">Core?</Form.Label>
                                <Form.Select onChange={(e) => setCore(e.target.value === "yes")}>
                                    <option value={"yes"}>Yes</option>
                                    <option value={"no"} selected>No</option>
                                </Form.Select>
                            </Form.Group>
                            <Button variant="primary" className="mt-2" onClick={addStandardCallback}>Add Standard</Button>
                        </div>}
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default TopicQuestionStandards
