import { ReactTable, useModel } from "@stem-sims/nexus"
import FAIcon from "components/General/FAIcon"
import { Field, Form, Formik } from "formik"
import React from "react"
import { Button, FormControl, FormGroup, FormLabel, Modal, Row } from "react-bootstrap"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { createLessonPlan, deleteLessonPlan, getLessonPlans, ImportAssignment, importLessonPlan, LessonPlanResponse, loadPlanImport } from "shared/routes/lessonPlans"

export default function LessonPlans() {
    const { response: lessonPlans, refreshModel } = useModel({
        model: getLessonPlans
    })

    const navigate = useNavigate()

    const [modal, setModal] = React.useState<"create" | "delete" | "importCodeEntry" | "importConfirm">(null)

    const [planImport, setPlanImport] = React.useState<{ name: string, description: string, assignments: ImportAssignment[] }>(null)

    const [deletePlan, setDeletePlan] = React.useState<LessonPlanResponse>(null)

    const tableData = React.useMemo(() => {
        if (!lessonPlans) {
            return []
        }

        return lessonPlans.map(i => ({ ...i, id: i.lessonPlanId }))
    }, [lessonPlans])

    const columns = [
        {
            Header: "Plan Name",
            accessor: "planName",
            Cell: ({ row }) => {
                return <div className="h-100 w-100 d-flex align-items-center text-start" style={{ cursor: "pointer" }} onClick={() => { navigate(row.original.lessonPlanId) }}>
                    {row.original.planName}
                </div>
            }
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ row }) => {
                return <div className="h-100 w-100 d-flex align-items-center text-start" style={{ cursor: "pointer" }} onClick={() => { navigate(row.original.lessonPlanId) }}>
                    {row.original.description}
                </div>
            }
        },
        {
            Header: "Actions",
            Cell: ({ row }) => {
                return <>
                    <Button variant="danger" onClick={() => {
                        setDeletePlan(row.original)
                        setModal("delete")
                    }}>
                        <span className="d-none d-md-inline">Remove</span>
                        <span className="d-inline d-md-none"><FAIcon iconName="trash-alt" /></span>
                    </Button>
                </>
            }
        }
    ]

    const importColumns = [
        {
            accessor: "order",
            maxWidth: 10,
            Header: () => (
                <div className="w-100 text-end">#</div>
            )
        },
        {
            Header: "Module Name",
            accessor: "moduleName"
        },
    ]

    return <>
        <Row className="text-start my-2 ms-2">
            <h1>Lesson Plans</h1>
            <h4 className="text-muted">
                Group assignments to easily add them to multiple classes.
            </h4>
        </Row>
        <Row className="my-2 ms-2">
            <div className="text-start">
                <Button
                    onClick={() => setModal("create")}>
                    + Add New Plan
                </Button>

                <Button className="ms-2" onClick={() => setModal("importCodeEntry")}>
                    Import Plan
                </Button>
            </div>
        </Row>

        <div className="mx-2">
            <ReactTable
                hideFilter={true}
                bulkActions={[]}
                columns={columns}
                data={tableData}
            />
        </div>

        <Modal show={modal === "create"} onHide={() => setModal(null)}>
            <Formik initialValues={{ name: "", description: "" }} onSubmit={async ({ name, description }) => {
                await createLessonPlan({ name, description })
                refreshModel()
                setModal(null)
            }}>
                <Form>
                    <Modal.Header closeButton>
                        Create New Lesson Plan
                    </Modal.Header>

                    <Modal.Body>

                        <FormGroup controlId="name">
                            <FormLabel>Name</FormLabel>
                            <FormControl as={Field} name="name" type="text" placeholder="Plan Name"></FormControl>
                        </FormGroup>

                        <FormGroup controlId="description">
                            <FormLabel>Description</FormLabel>
                            <FormControl as={Field} name="description" type="text" placeholder="Plan Description"></FormControl>
                        </FormGroup>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" variant="theme">Create</Button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>

        <Modal show={modal === "delete"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>Deleting Plan {deletePlan?.planName}</Modal.Header>

            <Modal.Body>
                <p>Are you sure you wish to delete lesson plan {deletePlan?.planName}?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(null)}>Cancel</Button>
                <Button variant="danger" onClick={async () => {
                    await deleteLessonPlan({ planId: deletePlan?.lessonPlanId })
                    refreshModel()
                    setModal(null)
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={modal === "importCodeEntry"} onShow={() => setPlanImport(null)} onHide={() => setModal(null)}>
            <Formik
                initialValues={{ shareCode: "" }}
                onSubmit={async ({ shareCode }) => {
                    const planImport = await loadPlanImport({ shareCode })
                    if (!planImport) {
                        toast.error("Unable to load lesson plan, please check share code spelling and try again.")
                        return
                    }

                    setPlanImport(planImport)
                    setModal("importConfirm")
                }}
            >
                <Form>
                    <Modal.Header closeButton>Import Plan</Modal.Header>

                    <Modal.Body>
                        <FormGroup>
                            <FormLabel>Enter share code</FormLabel>
                            <FormControl name="shareCode" as={Field} />
                        </FormGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModal(null)}>Cancel</Button>
                        <Button variant="theme" type="submit">Import</Button>
                    </Modal.Footer>

                </Form>
            </Formik>
        </Modal>

        <Modal show={modal === "importConfirm"} onHide={() => { setModal(null); setPlanImport(null) }}>
            <Formik
                initialValues={{ name: planImport?.name, description: planImport?.description }}
                onSubmit={async ({ name, description }) => {
                    await importLessonPlan({
                        name: name,
                        description: description,
                        assignments: planImport?.assignments
                    })
                    refreshModel()
                    setModal(null)
                    setPlanImport(null)
                }}
            >
                <Form>
                    <Modal.Header closeButton>Import Plan</Modal.Header>

                    <Modal.Body className="text-start">

                        <FormGroup>
                            <FormLabel>Plan Name</FormLabel>
                            <FormControl as={Field} name="name" />
                        </FormGroup>

                        <FormGroup className="pt-2">
                            <FormLabel>Description</FormLabel>
                            <FormControl as={Field} name="description" />
                        </FormGroup>

                        <p className="pt-2">Assignments</p>
                        <ReactTable
                            bulkActions={[]}
                            hideFilter
                            columns={importColumns}
                            data={(planImport?.assignments ?? []).map((a) => ({ ...a, id: a.order }))}
                        />

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setModal(null); setPlanImport(null) }}>Cancel</Button>
                        <Button variant="theme" type="submit">Import</Button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
    </>
}
