import { Form } from 'react-bootstrap'

export const HideCompletedToggle: React.FC<{onClick: () => void}> = ({ onClick }) => {

  return (
    <Form className="text-start text-primary fw-bold">
        <Form.Check
            onChange={onClick}
            type="switch"
            id="hide-complete-switch"
            label={<span>Show Submitted <span className="d-none d-sm-inline">Assignments</span></span>}
        />
    </Form>
  )
}

export default HideCompletedToggle
