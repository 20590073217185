import styles from "./styles.module.scss"
import variables from "../../scss/variables.module.scss"
import { SectionHeader } from "./AssignmentTopic"
import { useEffect, useState } from "react"
import titleCase from "helpers/titleCase"

interface Props {
    currentSectionNumber: number
    sectionHeaders: SectionHeader[]
    jumpToSection: (sectionNumber: number) => Promise<void>
}

interface StepProps {
    header: SectionHeader
    active: boolean
    cx: number
    lx: number
    showLeadingLine: boolean
    isLastStep: boolean
    jumpToSection: () => Promise<void>
}

const Step = ({header, active, cx, lx, showLeadingLine, isLastStep, jumpToSection} : StepProps) => {
    
    const completeColor = variables["theme-color"]
    const incompleteColor = variables["desaturate-color"]
    const notVisitedColor = "#FFFFFF"

    const [onHover, setOnHover] = useState(false)

    return <>
        {active && !onHover &&
        <circle
            className={styles.progressStepHoverElement}
            cx={`${cx}`}
            cy={active ? "7" : "8"}
            r="3"
            fill="#000000" />}
        {onHover && 
        <text
            className={styles.progressStepHoverElement}
            textAnchor="middle"
            alignmentBaseline="central"
            dominantBaseline="middle"
            x={`${cx}`}
            y={active ? "7" : "8"}>{header.progress === "not-visited" ? "Not Started" : titleCase(header.progress)} {header.type === "Questions" && `(${header.completedQuestions} / ${header.totalQuestions})`}</text>}
        {showLeadingLine && <line
            x1={cx - 80}
            x2={cx - 10}
            y1="27"
            y2="27"
            stroke="#000000" />}
        <circle
            className={styles.progressStepCircle}
            role="button"
            onClick={jumpToSection}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            cx={`${cx}`}
            cy={"27"}
            r={active ? "12" : "7"}
            fill={header.progress === "complete" ? completeColor : header.progress === "incomplete" ? incompleteColor : notVisitedColor}
            stroke={header.progress === "complete" ? "#FFFFFF" : "#000000"} />
        <text
            role="button"
            onClick={jumpToSection}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            cx={`${cx}`}
            textAnchor="middle"
            data-testid={header.header}
            x={`${cx}`}
            y={active ? "54" : "53"}>{header.header}</text>
        {!isLastStep && <line
            x1={`${lx + (active ? 3 : -2)}`}
            x2={`${lx + 100}`}
            y1="27"
            y2="27"
            stroke="#000000" />}
    </>
}

const AssignmentTopicProgress = ({ currentSectionNumber, sectionHeaders, jumpToSection } : Props) => {
    let cx = 0
    let lx = 0
    const LIMIT = 7
    const [paginatedHeaders, setPaginatedHeaders] = useState<SectionHeader[]>(null)
    const [offset, setOffset] = useState(0)
    
    useEffect(()  => {
        if (sectionHeaders.length <= LIMIT) return setPaginatedHeaders(sectionHeaders)

        let start = Math.max(0, currentSectionNumber - Math.floor(LIMIT / 2))
        let end = start + LIMIT

        if (end > sectionHeaders.length) {
            end = sectionHeaders.length
            start = Math.max(0, end - LIMIT)
        }
        
        const slicedHeaders = sectionHeaders.slice(start, end)
        setPaginatedHeaders(slicedHeaders)

        setOffset(start)
    }, [currentSectionNumber, sectionHeaders])

    return (
        <svg
            overflow="visible"
            viewBox="0 0 800 60"
            className={styles.progressSvg}
            xmlns="http://www.w3.org/2000/svg">
                {paginatedHeaders?.map((header, index) => {
                    const originalIndex = index + offset
                    const active = currentSectionNumber === originalIndex

                    cx += 105
                    lx = cx + 10
                    return (
                        <Step
                            jumpToSection={async () => {
                                jumpToSection(originalIndex)
                            }}
                            key={`${header}-${index}`} 
                            header={header}
                            active={active}
                            cx={cx} 
                            lx={lx} 
                            showLeadingLine={originalIndex !== 0 && index === 0}
                            isLastStep={originalIndex === sectionHeaders.length - 1} />
                    )
                })}
        </svg>
    )
}

export default AssignmentTopicProgress
