import { CurriculumSection, TopicStudentAnswers } from "shared/types/curriculumTypes"
import SectionQuestions from "./SectionQuestions"
import { initTopicTable } from "components/Assignment/helpers/topicAssignmentHelper"
import { useContext, useEffect } from "react"
import { AuthContext } from "AuthContext"

interface Props {
    currentSection: CurriculumSection
    initialStudentAnswers: TopicStudentAnswers
    studentAnswers: TopicStudentAnswers
    setStudentAnswers: (newAnswers: TopicStudentAnswers) => void
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
}

const TopicSection = ({ currentSection, initialStudentAnswers, studentAnswers, setStudentAnswers, handleAnswerChange } : Props) => {

    const { isStudent } = useContext(AuthContext)

    /**
     * Load the existing topic table data and update the studentAnswers structure based on the table inputs
     */
    useEffect(() => {
        if (!currentSection) return
        initTopicTable({
            action: "load",
            section: currentSection,
            studentAnswers: initialStudentAnswers,
            setStudentAnswers: setStudentAnswers})
    }, [currentSection, initialStudentAnswers, setStudentAnswers])

    /**
     * Attach listeners to the topic table inputs
     */
    useEffect(() => {
        if (!currentSection) return
        initTopicTable({
            action: "attach",
            section: currentSection,
            setStudentAnswers: setStudentAnswers})
    }, [currentSection, setStudentAnswers])

    if (!currentSection) return <></>

    return (
        <div style={{height: "100vh"}}>
            <h1>{currentSection.header}</h1>

            {currentSection.type !== "PDF" &&
                <div className="d-flex justify-content-center" id="section-table">
                    <div className="text-start w-100" dangerouslySetInnerHTML={{ __html: currentSection.sectionText }} />
                </div>
            }

            {currentSection.type === "Questions" &&
                <SectionQuestions
                    currentSection={currentSection}
                    handleAnswerChange={handleAnswerChange}
                    initialStudentAnswers={initialStudentAnswers}
                    studentAnswers={studentAnswers} />
            }

            {currentSection.type === "HTML" && currentSection.content.map((item) => (
                (!item.teacherOnly || !isStudent) &&
                <div key={item.id} className="text-start" dangerouslySetInnerHTML={{ __html: item.content }} />
            ))}

            {currentSection.type === "PDF" && <iframe
                title={`Section ${currentSection.header} Embedded PDF`}
                src={currentSection.sectionText}
                height={1000}
                width={1000}
            />}

        </div>
    )
}

export default TopicSection
