import { LessonResponse } from "shared/lessons"
import { Tab, Tabs } from "react-bootstrap"
import ModuleBackground from "./ModuleBackground"
import LessonSpecificBackground from "./LessonSpecificBackground"

const LessonBackground = ({ lesson, refreshLesson } : {lesson: LessonResponse, refreshLesson: () => Promise<void> }) => {
  const editing = process.env.REACT_APP_EDITING === "true"

  if (!lesson) return null

  return (
    <div>
        <div>
            {editing && 
              <Tabs
                defaultActiveKey={"module-background"}
                id="module-lesson-tabs"
                className="mb-3 d-flex justify-content-center">
                <Tab eventKey="module-background" title="Module">
                  <ModuleBackground lesson={lesson} />
                </Tab>
                <Tab eventKey="lesson-background" title="Lesson">
                  <LessonSpecificBackground lesson={lesson} refreshLesson={refreshLesson} />
                </Tab>
              </Tabs>}

              {!editing && 
              <div>
                {lesson.background ? <LessonSpecificBackground lesson={lesson} refreshLesson={refreshLesson} /> : <ModuleBackground lesson={lesson} />}
              </div>
              }
        </div>
    </div>
  )
}



export default LessonBackground
