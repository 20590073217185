import * as React from "react"
import Skeleton from "react-loading-skeleton"
import ToolTip from "../../General/ToolTip"
import tableStyles from "scss/TeacherAssignments.module.scss"
import { AssignmentResponse } from "shared/types/teacherTypes"
import { formatToLongDate } from "helpers/dateHelper"
import styles from "../General/general.module.scss"
import { ReactTable } from "@stem-sims/nexus"
import { AddAssignmentButton } from "../Teacher/Assignments/ExtraComponents/AddAssignmentButton"
import { AddTopicButton } from "../Teacher/Assignments/ExtraComponents/AddTopicButton"
import { AuthContext } from "AuthContext"
import { StudentAssignmentResponse } from "shared/types/studentTypes"
import HideCompletedToggle from "../Student/components/HideCompletedToggle"

interface AssignmentsProps {
    assignments: AssignmentResponse[] | StudentAssignmentResponse[]
    loading: boolean
    onRowClick: (row) => void
    changeBackgroundColor: (row) => string
    onAddAssignment?: () => void
    onAddTopicAssignment?: () => void
    rowTooltip: string
    extraCols: any[]
    checkboxCol?: any
}

const isStudentAssignments = (isStudent: boolean, assignments: AssignmentResponse[] | StudentAssignmentResponse[]): assignments is StudentAssignmentResponse[] => {
    return isStudent
}

export default function Assignments({ assignments, loading, onRowClick, changeBackgroundColor, rowTooltip, extraCols, checkboxCol, onAddAssignment, onAddTopicAssignment }: AssignmentsProps) {

    const { isStudent, hasCurriculumAccess } = React.useContext(AuthContext)

    const [filteredAssignments, setFilteredAssignments] = React.useState<StudentAssignmentResponse[]>([])

    const dateStringSort = React.useMemo(
        () => (rowA, rowB) => {
            const dateAValue = rowA.values.dueDate;
            const dateBValue = rowB.values.dueDate;
            if (dateAValue === 'N/A' && dateBValue === 'N/A') return 0;
            if (dateAValue === 'N/A') return 1; 
            if (dateBValue === 'N/A') return -1; 
    
            const dateA = new Date(dateAValue);
            const dateB = new Date(dateBValue);
            const diff = dateA.getTime() - dateB.getTime();
    
            return diff > 0 ? 1 : diff < 0 ? -1 : 0;
        },
        []
    )

    const columns = [
        {
            Header: 'Title',
            minWidth: 225,
            accessor: 'title',
            Cell: ({ row }) => {
                const assignment: AssignmentResponse = row.original
                const topicType = !!assignment.topicId
                const lessonType = !!assignment.lessonId
                return <ToolTip title={rowTooltip}>
                    <div className="text-start">
                        <span className="me-2">{assignment.title}</span>
                        <span className={`rounded px-2 ${topicType ? styles.labelTagTopic : lessonType ? styles.labelTagLesson : styles.labelTagAssessment}`}>
                            {topicType ? "topic" : (lessonType ? "lesson" : (assignment.lessonVariety === "Pure Inquiry" ? "pure inquiry" : "assessment"))}
                        </span>
                    </div>
                </ToolTip>
            }
        },
        {
            Header: 'Module Name',
            id: 'lesson.module',
            accessor: (assignment) => assignment.moduleName ? assignment.moduleName : (assignment.lesson?.module ? assignment.lesson.module : 'N/A'),
            Cell: ({ row }) => {
                const assignment: AssignmentResponse = row.original
                return (<ToolTip title={rowTooltip}>
                    <div className="text-center">
                        {assignment.moduleName ?? 'N/A'}
                    </div>
                </ToolTip>)
            },
        },
        {
            Header: 'Lesson Number',
            id: 'lesson.number',
            accessor: (assignment) => assignment.lesson?.number,
            maxWidth: 90,
            Cell: ({ row }) => {
                return <ToolTip title={rowTooltip}>
                    <div>{row.original.lesson?.number ?? "N/A"}</div>
                </ToolTip>
            }
        },
        {
            Header: 'Due Date',
            accessor: (data) => data.dueDate ? formatToLongDate(data.dueDate) : 'N/A',
            id: 'dueDate',
            sortType: dateStringSort
        },      
    ]
    columns.push(...extraCols)
    if (checkboxCol) {
        columns.unshift(checkboxCol)
    }

    const extraTeacherComponents = [{component: AddAssignmentButton, prop: onAddAssignment}]
    if (hasCurriculumAccess) {
        extraTeacherComponents.push({component: AddTopicButton, prop: onAddTopicAssignment})
    }

    const onHideCompletedAssignments = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStudentAssignments(isStudent, assignments)) return

        const show = e.currentTarget.checked === true
        if (show) {
            setFilteredAssignments(assignments)
        } else {
            const incompleteAssignments = assignments.filter(a => a.status === "Not Submitted" || a.status === "Incomplete" || a.status === "Rework Required")
            setFilteredAssignments(incompleteAssignments)
        }
    }, [assignments, isStudent])

    const extraStudentComponents = [{component: HideCompletedToggle, prop: onHideCompletedAssignments}]

    React.useEffect(() => {
        if (!assignments || assignments.length === 0 || !isStudentAssignments(isStudent, assignments)) return

        const incompleteAssignments = assignments.filter(a => a.status === "Not Submitted" || a.status === "Incomplete" || a.status === "Rework Required")
        setFilteredAssignments(incompleteAssignments)
    }, [assignments, isStudent])

    return (<>
        {loading ? <div className="mt-5">
            <Skeleton height={40} count={6} />
        </div>
        : 
        <div className={`${tableStyles.reactTable} class-assignments`}>
            <ReactTable columns={columns} data={isStudent ? filteredAssignments : assignments} extraComponents={isStudent ? extraStudentComponents : extraTeacherComponents} clickRow={onRowClick} customClass={changeBackgroundColor} sortBy={[{ id: 'dueDate' }]} bulkActions={[]} />
        </div>
        }
    </>)
}
