import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import Table from "react-bootstrap/Table"
import Skeleton from "react-loading-skeleton"
import { useModel } from "@stem-sims/nexus"
import generalStyles from "../General/general.module.scss"
import { formatToNumericalDate } from "../../../helpers/dateHelper"
import student from "shared/routes/student"
import { AssignmentSubmissionResponse, ClassResponse, StudentAssignmentResponse } from "shared/types/studentTypes"
import ToolTip from "components/General/ToolTip"
import EmptyTablePreview from "../General/EmptyTablePreview"
import { StudentSubmissionsState } from "./Submissions"
import { DateString } from "shared/types/commonTypes"
import styles from "./Student.module.scss"
import { Col, Row } from "react-bootstrap"
import { calculateLetterGrade } from "helpers/gradesHelper"
import { useEffect, useState } from "react"
import roundToFixed from "shared/helpers/roundToFixed"

interface Props {
    activeClass: ClassResponse
}

export interface ShowGradeProps {
    gradeType: "assessment" | "lesson" | "topic" | "overall"
    submission: AssignmentSubmissionResponse
    assignment: StudentAssignmentResponse
    showRecentLabel?: boolean
}

interface GetGradeProps {
    gradeType: "assessment" | "lesson" | "topic" | "overall"
    assignment: StudentAssignmentResponse
    submissionSelection?: "highest" | "lowest" | "average" | "recent" 
}

function ConditionalTooltip({showTooltip, title, children}: {showTooltip: boolean, title: string, children: unknown}) {
    if (showTooltip) {
        return <ToolTip title={title}>{children}</ToolTip>
    } else {
        return <>{children}</>
    }
}

export function showGrade({ gradeType, submission, assignment, showRecentLabel}: ShowGradeProps) {
    let gradePercentage: number | null = null
    switch(gradeType) {
        case "overall":
            gradePercentage = submission?.grade
            break
        case "assessment":
            gradePercentage = submission?.assessment?.percentage
            break
        case "lesson":
            gradePercentage = submission?.lesson?.percentage
            break
        case "topic":
            gradePercentage = submission?.topic?.actual
            break
    }
    if (gradePercentage || gradePercentage === 0) {
        const IntFormatter = new Intl.NumberFormat(undefined, { maximumSignificantDigits: 3 })
        const recentGradedSubmission = assignment.submissions.find(sub => sub.status === "graded")
        const multipleSubmissionsExist = showRecentLabel && assignment.submissions.length > 1 && submission.id === recentGradedSubmission?.id
        return (
            <span>
                <ConditionalTooltip showTooltip={multipleSubmissionsExist} title="Most Recent" key={assignment.id}>
                    <span className={multipleSubmissionsExist ? "text-primary fw-bold" : ""}>{submission.status === "rework-required" ? "---" : `${IntFormatter.format(gradePercentage)}%`}</span>
                </ConditionalTooltip>
            </span>
        )
    }
    const assessmentOnly = assignment.assessmentId && !assignment.lessonId
    if(gradeType === "lesson" && !assessmentOnly && submission?.lesson?.lessonExists) { // only lessons are currently graded by teacher, assessments only should show N/A for lesson grade
        const displayedGrade = (submission?.lesson?.dateSubmitted) ? "Grading in progress" : "Incomplete"
        return displayedGrade
    }
    return "N/A"
}

function getGrade({ gradeType, assignment, submissionSelection="recent" }: GetGradeProps) {
    if(assignment.status !== "Complete" && assignment.status !== "Graded")  {
        return ""
    }
    if (submissionSelection === "recent") {
        var recentSubmission = assignment.submissions.find((sub) => sub.status === "graded") // choose the most recent graded submission
    }
    return showGrade({ gradeType, submission: recentSubmission, assignment, showRecentLabel: true})
}



export default function StudentGrades({ activeClass }: Props) {
    const navigate = useNavigate()
    const [avgClassGrade, setAvgClassGrade] = useState(0)

    const { response: grades, loading } = useModel({
        model: student.getAssignments,
        onError: (err) => (toast.error(err.response?.data?.message ?? "There was an error loading the grades.")),
        props: { classId: activeClass.id }
    })

    useEffect(() => {
        if (!grades || grades.length === 0) return

        let allGrade = 0
        let totalSubmissions = 0

        for (const assignment of grades) {
            for (const sub of assignment.submissions) {
                allGrade += sub.status === "graded" ? sub.grade : 0
                totalSubmissions += sub.status === "graded" ? 1 : 0
            }
        }

        setAvgClassGrade(totalSubmissions > 0 ? allGrade / totalSubmissions : 0)
    }, [grades])

    const navigateToSubmissions = (assignment: StudentAssignmentResponse) => {
        const canNavigate = assignment.status !== "Incomplete" && assignment.status !== "Not Submitted"
        if (canNavigate) {
            navigate("/dashboard/student/grades/submissions", {
                state: {
                    assignment: assignment,
                    userType: "Student",
                    studentId: assignment.studentId,
                    previousPage: "/dashboard/student/grades"
                } as StudentSubmissionsState
            })
        }
    }
    const { submissionSelection } = activeClass.gradeInformation
    return (<>
        <div className="d-block d-md-flex justify-content-between me-5 mt-3">
            <div>
                <h1 className="text-start">Grades</h1>
                <p className={`text-start ${generalStyles.greyText}`}>
                    View the status of your grades.
                </p>
            </div>
            <Row className={`w-auto rounded p-1 class-grade-background ms-1 align-items-center`}>
                <Col sm={3}>
                    <strong style={{ fontSize: "45px" }}>{calculateLetterGrade(avgClassGrade)}</strong>
                </Col>
                <Col className="text-sm-start mt-3">
                    <div><span className="h3">{roundToFixed(avgClassGrade, 2)}%</span></div>
                    <p className="text-muted">Class Grade</p>
                </Col>
            </Row>
        </div>
        {loading && <>
            <Skeleton height={40} count={5} />
        </>}
        {!loading && (grades?.length > 0 ? <Table hover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Due Date</th>
                    <th>Due Status</th>
                    <th>Overall Grade</th>
                </tr>
            </thead>
            <tbody>
                {grades.map((assignment) => {
                    const canViewSubmission = assignment.status !== "Incomplete" && assignment.status !== "Not Submitted"
                    return (
                            <ConditionalTooltip showTooltip={canViewSubmission} title="View all submissions" key={assignment.id}>
                                <tr role={canViewSubmission ? "button" : ""} className={`${!assignment.userHasAccess && `${styles.studentGrade}`}`} onClick={() => navigateToSubmissions(assignment)}
                                >
                                    <td className="text-start">{assignment.title}</td>
                                    <td>
                                        {formatToNumericalDate(assignment.dueDate as unknown as DateString)} {/* due date is typed as date on backend but returns as string, made a ticket to fix this: STEM-971  */}
                                    </td>
                                    <td>{assignment.status}</td>
                                    <td>{getGrade({
                                        gradeType: "overall",
                                        submissionSelection,
                                        assignment
                                    })}</td>
                                </tr>
                            </ConditionalTooltip>
                        )
                    })
                }
            </tbody>
        </Table>
        :
        <EmptyTablePreview title="Grades" description="You have not submitted any assignments yet." />
        )}
    </>)
}
