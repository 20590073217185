import modules from "shared/routes/moduleRoutes"
import { postSubmissions } from "shared/routes/assignments"
import { Submission } from "shared/types/assignmentTypes"
import { LessonVariety } from "shared/types/moduleTypes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import { StudentAssignmentResponse } from "shared/types/studentTypes"

const getSubmissionIdWithLesson = async ({ assignment, recentSubmission }: { assignment: StudentAssignmentResponse, recentSubmission: Submission }): Promise<string> => {
    const incompleteStatuses = ["incomplete", "rework-required"]
    
    if (incompleteStatuses.includes(recentSubmission.status)) {
        return recentSubmission.id
    } else {
        const response = await modules.getLessonSubmission({
            moduleName: assignment.moduleName,
            lessonType: assignment.type,
            lessonNumber: assignment.lesson?.number,
            studentLessonId: recentSubmission.studentLessonId
        })
        const prevSubmission = assignment.submissions?.[0]

        if (!response.completed) {
            return recentSubmission.id
        } else if (response.completed && (prevSubmission.assessment.possible === null && !response.grade)) {
            // if lesson has been submitted but not the assessment and assignment has not been graded, use the existing (recent) submission id instead of starting a new submission
            return recentSubmission.id
        } else {
            const submission = await postSubmissions({ assignmentId: assignment.id })
            return submission.data.id
        }
    }
}

const getSubmissionIdPureInquiry = async ({ assignmentId, recentSubmission, lessonVariety }: { assignmentId: string, recentSubmission: any, lessonVariety: LessonVariety }): Promise<string> => {
    if (!recentSubmission.studentPureInquiryId) {
        return recentSubmission.id
    } else if (lessonVariety === "Pure Inquiry") {
        const { data: response } = await pureInquiry.get({ pureInquiryId: recentSubmission.studentPureInquiryId })
        if (!response.completed) {
            return recentSubmission.id
        } else {
            const submission = await postSubmissions({ assignmentId: assignmentId })
            return submission.data.id
        }
    }
}

 async function getStudentSubmissionId ({ assignment, recentSubmission } : { assignment: StudentAssignmentResponse, recentSubmission: Submission }) : Promise<string> {
    let submissionId: string
    try {
        const assessmentOnly = !assignment.lesson?.number && !!assignment.assessmentId

        if (!recentSubmission || assessmentOnly) {
            const submission = await postSubmissions({ assignmentId: assignment.id })
            submissionId = submission.data.id
        } else if(assignment.lesson?.number) {
            submissionId = await getSubmissionIdWithLesson({
                assignment: assignment,
                recentSubmission: recentSubmission
            })
        } else {
            submissionId = await getSubmissionIdPureInquiry({
                assignmentId: assignment.id,
                recentSubmission: recentSubmission,
                lessonVariety: assignment.lessonVariety,
            })
        }
        return submissionId
    } catch (err) {
        throw err
    }
}

const startNewSubmission = async (assignmentId: string): Promise<string> => {
    const submission = await postSubmissions({ assignmentId: assignmentId })
    return submission.data.id
}

const getTopicSubmissionId = async ({ assignment, recentSubmission } : { assignment: StudentAssignmentResponse, recentSubmission: Submission }) => {
    const completedStatuses = ["graded", "submitted", "rejected"]

    // start a new submission if there is no previous submission or if previous submission has one of the completed statuses
    // otherwise, use the existing submission id to continue the assignment
    if (!recentSubmission || completedStatuses.includes(recentSubmission.status)) {
        const newSubmission = await postSubmissions({ assignmentId: assignment.id })
        return newSubmission.data.id
    } else {
        return recentSubmission?.id
    }
}

export {
    getStudentSubmissionId,
    startNewSubmission,
    getTopicSubmissionId
}
