import { Row, Col } from "react-bootstrap"
import { ClassResponse, StudentAssignmentResponse } from "shared/types/studentTypes"
import OverviewAssignments from "./OverviewAssignments"
import styles from "./Student.module.scss"
import { toast } from "react-toastify"
import { useModel } from "@stem-sims/nexus"
import Skeleton from "react-loading-skeleton"
import Calendar from "../General/Calendar"
import students from "shared/routes/student"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "AuthContext"

interface Props {
    activeClass: ClassResponse
}

export type CalendarAssignment = {
    title: string,
    date: string,
    backgroundColor?: string
}

export default function Overview({ activeClass }: Props) {
    const [filteredAssignments, setFilteredAssignments] = useState<StudentAssignmentResponse[]>([])
    const { studentInfo } = useContext(AuthContext)

    const { response: assignments, loading} = useModel({
        model: students.getAssignments,
        onError: (err) => (toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")),
        props: { classId: activeClass?.id }
    })

    useEffect(() => {
        if (loading) return
        // Put the assignments with no due date at the top of the list
        assignments?.sort((a1, a2) => {
            if (a1.status === "Rework Required" && a2.status !== "Rework Required") {
                return -1
            } else if (a1.status !== "Rework Required" && a2.status === "Rework Required") {
                return 1
            } else if (a1.dueDate === null && a2.dueDate !== null) {
                return -1
            } else if (a1.dueDate !== null && a2.dueDate === null) {
                return 1
            } else if (new Date(a1.dueDate) > new Date(a2.dueDate)) {
                return 1
            } else {
                return -1
            }
        })

        // Filter out assignments with submission(s)
        setFilteredAssignments(assignments?.filter(a => a.status === "Not Submitted" || a.status === "Incomplete" || a.status === "Rework Required") ?? [])
    }, [assignments, loading])

    return (
        <div className="overflow-x-hidden overflow-y-auto">
            <div className={`${styles.studentNameRow}`}>
                <p className={`${styles.studentName} p-2`}>
                    <i className="fa-solid fa-user text-primary ms-2" /> <span className="fw-bold">{studentInfo.name}</span>
                </p>
                <h1 className="text-center fw-bold">Dashboard</h1>
            </div>
            <Row className="d-flex justify-content-center">
                <Col sm={12} md={12} lg={6}>
                    {loading ?  <Skeleton height={20} className={`${styles.bgLightBlue} mb-2`} /> : <OverviewAssignments filteredAssignments={filteredAssignments} />}
                </Col>
                <Col className={styles.calendarContainer} sm={12} md={12} lg={6}>
                    <Calendar assignments={loading ? [] : assignments} />
                </Col>
            </Row>
        </div>
    )
}
