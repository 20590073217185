import { Dispatch, SetStateAction } from "react"
import { TopicSectionResponse, TopicSubmissionResponse } from "shared/types/moduleTypes"
import LessonResponseItem from "./LessonResponseItem"
import AssessmentResponseItem from "./AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import modules from "shared/routes/moduleRoutes"
import DrawingResponseItem from "./DrawingResponseItem"
import ItemGradeInput from "../components/ItemGradeInput"
import { Col, Row } from "react-bootstrap"
import styles from "../submissionTeacher.module.scss"
import TableResponseItem from "./TableResponseItem"

interface Props {
    topicSubmission: TopicSubmissionResponse
    setTopicSubmission: Dispatch<SetStateAction<any>>
    topic: CurriculumTopic
    studentTopicId: string
    section: TopicSectionResponse
    refresh: () => void
    reloadTableResponses: () => void
}

export interface UpdateTopicQuestionProps {
    studentTopicAnswerId: string,
    grade?: number,
    comment?: string
}

const TeacherTopicResponses = ({ topicSubmission, setTopicSubmission, topic, section, studentTopicId, refresh, reloadTableResponses } : Props) => {

    const sectionData = topic.sections.find((v) => v.id === section.id)
    const sectionText = sectionData?.sectionText ?? ""

    const tableExists = sectionText.includes("<table") && (sectionText.includes("<input") || sectionText.includes("<textarea"))

    const sumTopicPoints = (topicSummary: TopicSubmissionResponse) => {
        return topicSummary.sections.filter(s => s.type === "Questions").map(s => {
            return section.responses?.map(r => r.grade ?? 0).reduce((a, i) => a + i)
        }).reduce((a, i) => a + i)
    }
    const [expand, toggleExpand] = useBoolean(true)
    const [hideSectionText, toggleHideSectionText] = useBoolean(!tableExists)

    const updateTopicQuestion = async ({ studentTopicAnswerId, grade = undefined, comment = undefined } : UpdateTopicQuestionProps) => {
        await modules.updateTopicQuestionGrade({ studentTopicAnswerId, studentTopicId, grade, comment })
        return refresh()
    }

    const firstTableResponse = topicSubmission.tableResponses?.find((v) => v.sectionId === section.id && v.tableNumber === 1 )

  return (
    <div>
        <div className="d-flex justify-content-center">
            <h3 
                role="button"
                onClick={() => {
                    toggleExpand()
                    reloadTableResponses()
                }}
                className={`flex-grow-1 text-center fw-bold ${expand && `mb-3`}`}>
                    {section.header}
            </h3>
            <i 
                role="button" 
                className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} 
                onClick={() => {
                    toggleExpand()
                    reloadTableResponses()
                }} />
        </div>

        {expand && !tableExists && sectionData?.sectionText &&
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="mb-3 text-muted fw-bold" onClick={toggleHideSectionText}>
                    <span role="button" className="me-2">{hideSectionText ? "Show" : "Hide"} Section Text</span>
                    <i role="button" className={`fas ${hideSectionText ? `fa-chevron-down` : `fa-chevron-up`} ms-auto`}  />
                </div>
                {!hideSectionText && <div className="question-content p-4 border border-1 border-info rounded shadow-sm" dangerouslySetInnerHTML={{ __html: sectionData.sectionText }}></div>}
            </div>}

          {expand && <ol className="p-4">
              <>
                  {tableExists && <>
                    <div className="question-content mb-5 px-3" dangerouslySetInnerHTML={{ __html: sectionData.sectionText }}></div>
                    <div>
                        <ItemGradeInput
                            currentValue={firstTableResponse?.grade ?? 0}
                            setValue={async (value) => {
                                await modules.updateTopicTableGrade({ sectionId: section.id, studentTopicId: studentTopicId, grade: value })
                                return refresh()
                            }}
                        />
                    </div>
                    <Row>
                        <Col>
                            <p className="mb-2"><strong>Comments</strong></p>
                            <textarea
                                className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                                defaultValue={firstTableResponse?.comment ?? ""}
                                onBlur={async (e) => {
                                    await modules.updateTopicTableGrade({ sectionId: section.id, studentTopicId: studentTopicId, comment: e.currentTarget.value })
                                    return refresh()
                                }}
                            />
                        </Col>
                    </Row>
                  </>}
              </>

              {section.responses?.map((response, index) => {
                  if (response.questionType === "Text") {
                      return <LessonResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          responseType="topic"
                          submission={topicSubmission}
                          response={response}
                          setSubmission={setTopicSubmission}
                          updateQuestion={updateTopicQuestion}
                          sumPoints={sumTopicPoints} />
                  } else if (response.questionType === "MultipleChoice") {
                      return <AssessmentResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          response={response} />
                  } else if (response.questionType === "Table") {
                    return <TableResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          responseType="topic"
                          submission={topicSubmission}
                          response={response}
                          setSubmission={setTopicSubmission}
                          updateQuestion={updateTopicQuestion}
                          sumPoints={sumTopicPoints}
                    />
                  } else{
                      return <DrawingResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          responseType="topic"
                          index={index}
                          response={response}
                          updateQuestion={updateTopicQuestion}
                          submission={topicSubmission}
                          setSubmission={setTopicSubmission}
                          sumPoints={sumTopicPoints} />
                  }
              })}
          </ol>}

    </div>
  )
}

export default TeacherTopicResponses
