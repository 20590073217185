import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import Button from "react-bootstrap/Button"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { Field, Form, Formik } from "formik"
import lessonModel, { Section } from '../../../shared/lessons'
import LessonTable from "./LessonTable"
import LessonIntro from "./LessonIntro"
import styles from "./styles.module.scss"
import { LessonContext, LessonBodyContext } from "../LessonContext"
import { sanitizePath } from "shared/helpers/moduleHelper"
import Tasks from "./Tasks"
import { AuthContext } from "AuthContext"
import { saveGuidedLesson, saveLessonInterval } from "./helpers/lessonHelper"
import fillFormData from "helpers/fillFormData"
import { toast } from "react-toastify"
import moduleRoutes from "shared/routes/moduleRoutes"
import { initLessonDrawingResponses, loadLessonDrawingData } from "components/Assignment/helpers/drawingHelper"
import { AssignmentTopicContext } from "components/Assignment/AssignmentTopicContext"
import { DrawingAnswer } from "shared/types/studentTypes"
import LessonBackground from "./LessonBackground/LessonBackground"
import { parseFormData } from "helpers/formDataHelper"
import { AssignmentContext } from "components/Assignment/AssignmentContext"

type LessonBodyProps = {
    lessonFormRef?: any
    initialLessonData?: Record<string, string>
    initialDrawingData?: Record<string, DrawingAnswer>
    submissionId?: string
}

const LessonBody = ({ lessonFormRef, initialLessonData, initialDrawingData, submissionId }: LessonBodyProps) => {
    const authContext = useContext(AuthContext)
    const { refreshTopicSectionProgressCheck } = useContext(AssignmentTopicContext)
    const { demoMode } = useContext(AssignmentContext)

    const [ sections, setSections ] = useState<Section[]>([])
    const [ currentSection, setCurrentSection ] = React.useState<number>(0)
    const [ checkpoint, setCheckpoint ] = useState<number>(0)
    const { lesson, submitLesson, refreshLesson, studentLessonIdRef, containerType, drawingResponses, setDrawingResponses } = useContext(LessonContext)

    const drawingResponsesRef = useRef<Record<string, DrawingAnswer>>({})
    useEffect(() => {
        drawingResponsesRef.current = drawingResponses
    }, [drawingResponses, drawingResponsesRef])

    const formikRef = React.useRef<any>()
    const saveLessonTimerRef = React.useRef(new Date())
    const tableIntervalRef = React.useRef(null)

    const editing = process.env.REACT_APP_EDITING === "true"

    const startTime = new Date().toISOString().slice(0,19).replace('T',' ')
    const lessonPath = `/simulations/${ sanitizePath(lesson.moduleName) }/lessons/lesson-${ lesson.number }-${ lesson.type.toLowerCase() }`
    const assessmentPath = `/simulations/assessment/?assessmentId=${lesson?.assessment?.id}&module=${lesson.moduleName}&assessmentNumber=${lesson?.assessmentNumber}&type=${ lesson.type.toLowerCase() }`

    const showSubmit = authContext.isStudent && authContext.trackingEnabled && !submissionId // submissionId is only passed in from the assignment lesson view
    const isTeacher = !authContext.isStudent && authContext.isLoggedIn

    const refreshSections = useCallback(async () => {
        const params = { module:lesson.moduleName,lessonNumber:lesson.number,type:lesson.type }
        lessonModel.getSections(lesson.id,params).then((sections) => {
            setSections(sections)
        })
    }, [lesson.moduleName, lesson.number, lesson.type, lesson.id])

    useEffect(() => {
        refreshSections()
    }, [refreshSections])

    const lessonBodyContextValues = {
        checkpoint: checkpoint, 
        setCheckpoint: setCheckpoint,
        lessonPath: lessonPath,
        refreshSections: refreshSections,
        sections: sections,
        setSections: setSections
    }

    const saveDemoModeLesson = useCallback(() => {
        const studentLessonResponses = {...parseFormData(lessonFormRef.current, true), ...(drawingResponsesRef.current)}
        localStorage.setItem("demoModeLessonResponses", JSON.stringify(studentLessonResponses))
    }, [lessonFormRef, drawingResponsesRef])

    const debouncedSaveLesson = useCallback(async ({ force = false } = { force: false }) => {
        if(!authContext.isLoggedIn || (isTeacher && !demoMode) || !authContext.trackingEnabled) return
        const now = new Date()
        if (!force && now.getTime() - saveLessonTimerRef.current.getTime() < saveLessonInterval) {
            return
        }
        saveLessonTimerRef.current = now

        if (demoMode) {
            saveDemoModeLesson()
            return
        }

        return saveGuidedLesson({
            lesson, completed: false, isTeacher, lessonFormRef,
            studentLessonIdRef, submissionId,
            drawingResponses: drawingResponsesRef.current })
        .catch((err) => {
            toast.error(err?.response?.data?.message ?? "There was an error automatically saving the lesson.")
        })
    }, [authContext.isLoggedIn, authContext.trackingEnabled, isTeacher, lesson, lessonFormRef, studentLessonIdRef, submissionId, drawingResponsesRef, demoMode, saveDemoModeLesson])

    useEffect(() => {
        debouncedSaveLesson()
        //try to autosave when drawing responses change, nothing else
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawingResponses])

    const attachListeners = useCallback((element: HTMLElement | Document) => {
        Array.from(element.getElementsByTagName("input")).forEach(element => {
            element.addEventListener("blur", () => {
                debouncedSaveLesson()
                refreshTopicSectionProgressCheck?.()
            })
        })
        Array.from(element.getElementsByTagName("textarea")).forEach(element => {
            element.addEventListener("blur", () => {
                debouncedSaveLesson()
                refreshTopicSectionProgressCheck?.()
            })
        })
    }, [debouncedSaveLesson, refreshTopicSectionProgressCheck])

    const removeListeners = useCallback((element: HTMLElement | Document) => {
        Array.from(element.getElementsByTagName("input")).forEach(element => {
            element.removeEventListener("blur", () => {
                debouncedSaveLesson()
                refreshTopicSectionProgressCheck?.()
            })
        })
        Array.from(element.getElementsByTagName("textarea")).forEach(element => {
            element.removeEventListener("blur", () => {
                debouncedSaveLesson()
                refreshTopicSectionProgressCheck?.()
            })
        })
    }, [debouncedSaveLesson, refreshTopicSectionProgressCheck])

    useEffect(() => {
        window.addEventListener("beforeunload", debouncedSaveLesson)
        attachListeners(document)
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            removeListeners(document)
        }
    },[lesson, debouncedSaveLesson, initialLessonData, formikRef, refreshSections, attachListeners, removeListeners])

    React.useEffect(() => {
        if (initialLessonData && lessonFormRef.current) {
            clearInterval(tableIntervalRef.current)
            tableIntervalRef.current = setInterval(() => {
                if (document.querySelector("[name=table-1]")) {
                    clearInterval(tableIntervalRef.current)
                    fillFormData(lessonFormRef.current, initialLessonData)
                }
            }, 10)
        }
        const newInitialValues = {...formikRef.current.values, ...initialLessonData}
        for (const key of Object.keys(newInitialValues)) {
            newInitialValues[key] = initialLessonData?.[key] ?? (key === "started" ? startTime : "")
        }
        formikRef.current.resetForm({ values: newInitialValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialLessonData, lessonFormRef.current])

    React.useEffect(() => {
        const updateModuleHit = async (moduleName: string) => {
            await moduleRoutes.updateModuleHit(moduleName)
        }

        if (authContext.isLoggedIn && authContext.isStudent) {
            updateModuleHit(lesson.moduleName)
        }
    }, [authContext.isLoggedIn, authContext.isStudent, lesson?.moduleName])

    React.useEffect(() => {
        const initialize = async () => {
            const data = await initLessonDrawingResponses(sections)
            const res = await loadLessonDrawingData(initialDrawingData, data)
            setDrawingResponses(res)
        }
        initialize()
    }, [sections, initialDrawingData, setDrawingResponses])

    return (
    <LessonBodyContext.Provider value={lessonBodyContextValues}>
        <Formik initialValues={{ ...initialLessonData ?? {}, started: startTime }} onSubmit={submitLesson} innerRef={formikRef}>
            {({ isSubmitting }) =>
                <Form ref={lessonFormRef} className={`${styles.lessonTabs} ${(lesson.type === "Tier 0" || lesson.type === "Tier 1") ? styles.elementary : ''} h-100`}>
                    {sections.length === 0 && editing && <>
                        <Button onClick={async () => {
                            await lessonModel.addSection(lesson.id)
                            refreshSections()
                        }} variant="default" className="btn-sm m-2 mb-3">Add Section</Button>
                    </>}
                    <Tabs
                        defaultActiveKey={'tasks'}
                        id="controlled-tab-example"
                        className={`d-flex d-print-none align-items-stretch mb-3`}
                    >
                        <Tab eventKey={'background'} title='Background' className={`${styles.lessonContent}`}>
                            <LessonBackground lesson={lesson} refreshLesson={refreshLesson} />
                        </Tab>
                        <Tab eventKey={'tasks'} title='Tasks' className={`${styles.lessonContent}`}>
                            <LessonIntro lesson={lesson} currentSection={currentSection} refreshCallback={refreshLesson} />
                            {sections.map((section, index) => currentSection === index && (<div key={section.title + index}>
                                <Tasks section={section} index={index} setCurrentSection={setCurrentSection} saveLesson={debouncedSaveLesson}  />
                            </div>))}
                        </Tab>
                        {(editing || lesson.tableHtml) &&
                            <Tab eventKey={'table'} className={`${styles.lessonContent}`} title="Table">
                                <LessonTable />
                            </Tab>
                        }
                        {sections.length > 1 && /* Rick stated 2nd section will always have questions, but just in case */
                            <Tab eventKey={'questions'} title="Questions" className={`${styles.lessonContent}`}>
                                <Tasks section={sections[1]} index={1} setCurrentSection={setCurrentSection} saveLesson={debouncedSaveLesson} />
                            </Tab>
                        }
                    </Tabs>
                    {/* for the lesson module page submit */}
                    <div>
                        <Button 
                            className={`${!showSubmit && 'd-none'} d-print-none float-end btn-theme me-3`}
                            disabled={isSubmitting}
                            onClick={() => {
                            formikRef.current.handleSubmit()
                        }}>
                            Submit
                        </Button>
                        <a href={assessmentPath} className={`${((!isTeacher && showSubmit) || !lesson.assessmentNumber || containerType !== "module") && 'd-none'} btn btn-theme float-end me-3`}>
                            View Assessment
                        </a>
                    </div>
                    <Field type="hidden" name="started" />
                </Form>}
        </Formik>
    </LessonBodyContext.Provider>
    )
}

export default LessonBody
