import { LessonResponse } from "shared/lessons"
import styles from "../styles.module.scss"
import { Table } from "react-bootstrap"
import CustomHTML from "components/CustomHTML"
import { sanitizePath } from "shared/helpers/moduleHelper"

const ModuleBackground = ({ lesson } : {lesson: LessonResponse}) => {
return (
    <div>
        {lesson.moduleBackground?.map((background, index) => {
        return (
            <div key={index} className="text-start">
            <h5><CustomHTML html={background.backgroundTitle} /></h5>
            <p><CustomHTML html={background.backgroundText} /></p>

            {background.images.length > 0 &&
                background.images.map((image, i) => 
                <div key={i} className="d-flex justify-content-center mt-2">
                    {image.caption && <div><CustomHTML html={image.caption} /></div>}
                    <img
                    className="text-center d-block img-fluid"
                    src={`/simulations/${sanitizePath(lesson.moduleName)}/background/background-${image.backgroundNumber}-image-${image.imageNumber}.png?version=${image.updated }`} 
                    alt={`${image.imageAlt ?? "Supplemental Background Figure"}`} />
                </div>)}
            {background.table.length > 0 &&
                <Table className={styles.backgroundTable} bordered>
                {background.table.map((row, rowIndex) => <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => {
                        return <td key={cellIndex} className="p-2"><CustomHTML html={cell} /></td>
                    })}
                </tr>)}
                </Table>}
            </div>
        )
        })}
    </div>
)
}

export default ModuleBackground
