import * as React from "react"
import { SidebarContext } from "./SidebarContext"
import styles from "./sidebar.module.scss"

interface SidebarProps {
    children: React.ReactNode
}

const Sidebar: React.FC = ({ children }: SidebarProps) => {
    const { collapsed } = React.useContext(SidebarContext)

    return (
        <div className={`${collapsed ? styles.sidebarMin : styles.sidebarFull} min-full-screen d-print-none`}>
            { children }
        </div>
    )
}

export default Sidebar
