import { AssignmentContext, AssignmentContextType } from "components/Assignment/AssignmentContext"
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm"
import { useNavigate, useParams } from "react-router"
import { useModel } from "@stem-sims/nexus"
import { getPlanAssignment } from "shared/routes/lessonPlans"
import React, { useEffect } from "react"
import lessons, { LessonResponse } from "shared/lessons"
import assessments, { AssessmentResponse } from "shared/routes/assessments/assessments"

export default function PlanAssignmentPreview() {
    const { assignmentId } = useParams()

    const navigate = useNavigate()

    const { response: planAssignment } = useModel({
        model: getPlanAssignment,
        props: { assignmentId },
    })

    const [assignmentContext, setAssignmentContext] = React.useState<AssignmentContextType>(null)

    const [assessment, setAssessment] = React.useState<AssessmentResponse>(null)

    useEffect(() => {
        if (!planAssignment) {
            return
        }

        let lesson: LessonResponse
        let tempAssessment: AssessmentResponse
        let promises: Promise<unknown>[] = []
        if (planAssignment.lessonId) {
            promises.push(lessons.findById(planAssignment.lessonId).then((resp) => { lesson = resp }))
        }

        if (planAssignment.assessmentId) {
            promises.push(assessments.findById({ id: planAssignment.assessmentId }).then(async (resp) => {
                setAssessment(resp)
                tempAssessment = resp
                const lessonResp = await lessons.findById(resp.lessonId)
                lesson = lessonResp
            }))
        }

        Promise.all(promises).then(() => {
            setAssignmentContext({
                activePart: planAssignment.topicId ? "topic" : planAssignment.lessonId ? "lesson" : "assessment",
                assessmentOnly: !planAssignment.lessonId && !planAssignment.topicId,
                guidedLesson: lesson ?? null,
                lessonVariety: planAssignment.topicId ? "Topic" : "Guided",
                moduleName: planAssignment.moduleName ?? lesson?.moduleName ?? tempAssessment?.module,
                type: lesson?.type,
                setActivePart: (activePart) => {
                    setAssignmentContext((oldContext) => {
                        return { ...oldContext, activePart: activePart }
                    })
                },
                demoMode: false,
                reworkAssignmentMode: false,
            })
        })

    }, [planAssignment])

    if (!planAssignment || !assignmentContext) {
        return null
    }

    return <>
        <AssignmentContext.Provider value={assignmentContext}>
            <AssignmentForm
                assessment={assessment}
                onSubmit={() => {
                    navigate(`/dashboard/teacher/lesson-plans/${planAssignment.lessonPlanId}`)
                }}
                submitButtonText="Return to Plan"
                topicId={planAssignment.topicId}
                initialValues={{
                    title: `Plan Assignment #${planAssignment.order}`,
                    dueDate: "",
                    notes: "",
                    assignmentType: planAssignment.topicId ? "topic" : planAssignment.lessonId ? "lesson" : "assessment",
                    studentAssessmentViewOption: "Grade",
                    students: []
                }}
            />
        </AssignmentContext.Provider>
    </>
}
