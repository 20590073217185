import axios from "axios"
import Papa from "papaparse"
import { ClassResponse, ClassAnalyticsResponse, 
    StudentResponse, CreateProps, AssignmentResponse,  
    AssignOption, CopyOption,
    SaqInfoResponse} from "../types/teacherTypes"
import { StringBoolean } from "shared/types/commonTypes"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"

/*************************
*       Class Methods    *
*************************/

interface ModifyClassProps {
    className: string,
    academicYear?: number,
    term?: string,
    gradeLevel?: string,
    standardId?: string
    studentAssessmentViewOption: AssessmentViewOptionsType
}

const TEACHER_CLASSES_URL = "/api/teacher/classes"

/**
 * Returns all classes that the currently logged in teacher has created
 */
export function getClasses(): Promise<ClassResponse[]> {
    return axios.get(TEACHER_CLASSES_URL)
        .then((response) => response.data)
}

export interface GetClassProps {
    classId: string
}

export async function getClass({ classId }: GetClassProps): Promise<ClassResponse> {
    const response = await axios.get(`/api/teacher/class/${classId}`)
    return response.data
}

/**
 * Creates a new class for the teacher that is currently logged in
 */
export function createClass(parameters: ModifyClassProps): Promise<{ message: "Created.", id: string }> {
    return axios.post(TEACHER_CLASSES_URL, parameters)
        .then((response) => response.data)
}

/**
 * Updates a class given any of the classes values
 */
export function updateClass(parameters: Partial<ModifyClassProps> & { id: string, archived?: StringBoolean, visible?: StringBoolean }) {
    return axios.put(TEACHER_CLASSES_URL, parameters)
        .then((response) => response.data)
}

/**
 * Deletes a class
 */
export function deleteClass(parameters: { id: string }) {
    return axios.delete(TEACHER_CLASSES_URL, {
        data: parameters
    })
        .then((response) => response.data)
}

/**
 * Gets analytics information for all classes administrated
 */
export async function getClassAnalytics() {
    const response = await axios.get("/api/teacher/classes/analytics") as { data: { classes: ClassAnalyticsResponse[] } }
    return response.data.classes
}

/*************************
*     Student Methods    *
*************************/

interface StudentToCreate {
    name: string
    emailAddress?: string
    ssoProvider?: "google"
    id?: string
}

interface StudentCreateProps {
    students: StudentToCreate[]
    classID: string
}

const TEACHER_STUDENTS_URL = "/api/teacher/students"

/**
 * Returns all students in a class. If no class id is provided then 
 * all the students in the teacher account is returned
 */
export function getStudents({ classID }: { classID?: string }): Promise<StudentResponse[]> {
    return axios.get(TEACHER_STUDENTS_URL, {
        params: { classID }
    }).then((response) => response.data)
}

export function getStudentsWithAssisgnmets({ classID }: { classID?: string }): Promise<StudentResponse[]> {
    return axios.get(TEACHER_STUDENTS_URL, {
        params: { classID }
    }).then((response) => response.data)
}

type convertCsvParams = {
    columns: string[]
    format?: (ary: string[]) => string
    extraMapping?: (row: Record<string, string>) => Record<string, string>
}


const objectKeysToLowerCase = function (obj) {
    if (typeof obj !== "object") return obj
    return Object.keys(obj).reduce(function (lowerCaseObj, key) {
        lowerCaseObj[key.toLowerCase()] = obj[key]
        return lowerCaseObj
    }, {})
}

export async function importStudentsFromCSV(rawCsv: string, classId: string, remainingLicenses?: number) {
    const parsedCsv = Papa.parse(rawCsv.trim(), { header: true })

    const convertCsv = ({ columns, format = (ary) => ary.join(" "), extraMapping = undefined }: convertCsvParams) => {
        const fields = parsedCsv.meta.fields?.map((fieldName: string) => fieldName.toLowerCase())
        if (!fields || !columns.every(colName => fields.includes(colName.toLowerCase()))) {
            return null
        }
        return parsedCsv.data.map((row: Record<string, string>) => {
            const lowerCaseRow = objectKeysToLowerCase(row)
            const student: StudentToCreate = {
                name: format(columns.map((colName) => lowerCaseRow[colName.toLowerCase()]))?.trim(),
                ...( extraMapping?.(row) ?? {} )
            }

            const { "email address": emailAddress, "sso provider": ssoProvider } = lowerCaseRow

            if (emailAddress && ssoProvider) {
                if (ssoProvider.toLowerCase() === "google") {
                    student.emailAddress = emailAddress
                    student.ssoProvider = "google"
                } else {
                    throw new Error(`Unsupported SSO Provider provided for ${student.name}: ${ssoProvider}`)
                }
            }

            return student
        }).reduce((acc, i) => {
            i.name !== null && i.name !== undefined && i.name !== "" && acc.push(i)
            return acc
        }, [])
    }
    const isGoogleExport = parsedCsv.data[0]?.["Last Name"] === "Date" && parsedCsv.data[1]?.["Last Name"] === "Points"
    const googleMapping = isGoogleExport ? (row: Record<string, string>) => {
        return {
            emailAddress: row["Email Address"],
        }
    } : undefined
    const students =
        // Google Classroom
        // from running an export
        convertCsv({
            columns: ["First Name", "Last Name"],
            format: (ary) => {
                if (ary[0] === "" && (ary[1] === "Date" || ary[1] === "Points")) {
                    return null
                } else {
                    return ary.join(" ")
                }
            },
            extraMapping: googleMapping,
        }) ||

        // Canvas (in Last, First format)
        // from running an export
        convertCsv({
            columns: ["Student"],
            format: (ary) => {
                if (ary[0].trim() === "Points Possible") {
                    return null
                } else {
                    return ary[0].split(" ").map(s => s.replace(",", "")).reverse().join(" ")
                }
            }
        }) ||

        // One Roster
        // from http://www.imsglobal.org/oneroster-v11-final-csv-tables#_Toc480293266
        convertCsv({ columns: ["givenName", "familyName"] }) ||

        //Clever
        // from https://docs.google.com/spreadsheets/u/1/d/e/2PACX-1vTY8WSC--TBok-cHjG8itGyqnrj7sCkfyWVzIxeLybwzryW01L9qD8xwhoJDBlWrjOkciOXV34G9ejH/pubhtml
        convertCsv({ columns: ["name.first", "name.last"] }) ||

        convertCsv({ columns: ["Student Name"] }) ||

        []

    if (students.length > remainingLicenses || !remainingLicenses) {
        throw new Error("You are not allowed to add more students than your license allows.")
    }

    if (students.length > 0) {
        return createStudents({ students, classID: classId })
    } else {
        throw new Error("Unable to read students from file.")
    }
}

/**
 * Create a generic student account (aka student class account)
 */
const TEACHER_ACCOUNT_URL = "/api/teacher/account"
export function createStudentClassAccount({ username, password, confirmPassword }) {
    return axios.post(`${TEACHER_ACCOUNT_URL}/student-class-create`, {
        username,
        password,
        confirmPassword
    }).then((response) => response.data)
}

/**
 * Check if student class account already exists for the teacher user
 */
export function checkIfStudentClassExists() : Promise<boolean> {
    return axios.get(`${TEACHER_ACCOUNT_URL}/class-student-exists`).then((response) => response.data)
}

/**
 * Create a new student in a given class
 */
export function createStudents({ students, classID }: StudentCreateProps): Promise<{ message: "Created.", uuids: string[] }> {
    return axios.post(TEACHER_STUDENTS_URL, {
        students,
        classID
    }).then((response) => response.data)
}

/**
 * Edit an existing student
 */
export function editStudent(props: Partial<StudentCreateProps> & { uuid: string }) {
    return axios.put(TEACHER_STUDENTS_URL, props)
        .then((response) => response.data)
}
/**
 * Delete a student
 */
export function deleteStudent(studentID: string): Promise<{ message: "Deleted." }> {
    return axios.delete(TEACHER_STUDENTS_URL, {
        data: {
            uuid: studentID
        }
    })
}

/**
 * Reset a students password
 */
export function resetPassword(studentID: string): Promise<string> {
    return axios.put("/api/teacher/students/password-reset", { id: studentID })
}

/**
 * Check teacher feature access
 */
export function teacherFeatureAccess(): Promise<boolean> {
    return axios.get("/api/teacher/hasTeacherFeatureAccess").then(res => res.data)
}

/*************************
*  Assignment Methods    *
*************************/

const TEACHER_ASSIGNMENT_URL = "/api/teacher/assignments"

export function createAssignment(props: CreateProps) {
    return axios.post(TEACHER_ASSIGNMENT_URL, props)
        .then((response) => response.data)
}

export function editAssignment(props: Partial<CreateProps> & { id: string }) {
    return axios.put(TEACHER_ASSIGNMENT_URL, props)
        .then((response) => response.data)
}

export function getAssignments({ classID }: { classID: string }): Promise<AssignmentResponse[]> {
    return axios.get(TEACHER_ASSIGNMENT_URL, {
        params: { classID }
    })
        .then((response) => response.data)
}
export function findAssignmentById(id: string): Promise<AssignmentResponse> {
    return axios.get(TEACHER_ASSIGNMENT_URL, {
        params: { id }
    })
        .then((response) => response.data)
}

export function deleteAssignments(props: { ids: string[] }): Promise<{ message: "Deleted." }> {
    return axios.delete(TEACHER_ASSIGNMENT_URL, {
        data: props
    })
}

/**
 * Bulk Operations routes
 */

export function bulkAssign({ assignmentIds, studentIds, assignOption } : { assignmentIds: string[], studentIds: string[], assignOption: AssignOption }) {
    const path = TEACHER_ASSIGNMENT_URL + "/bulk/assign"
    return axios.post(path, { assignmentIds, studentIds, assignOption })
        .then((response) => response.data)
}

export function bulkCopy({ copyOption, assignmentIds, classIds, lessonPlanIds } : { copyOption: CopyOption, assignmentIds: string[], classIds: string[], lessonPlanIds: string[] }) {
    const path = TEACHER_ASSIGNMENT_URL + "/bulk/copy"
    return axios.post(path, { copyOption, assignmentIds, classIds, lessonPlanIds })
        .then((response) => response.data)
}

/**
 * Get teacher license id
 */
export function teacherLicenseId() {
    return axios.get("/api/teacher/licenseId").then((response) => response.data)
}

/**
 * Check how many more students can be added
 */
export function remainingStudentCount(licenseId: string): Promise<{remaining: number}> {
    return axios.get(`/api/licenses/${licenseId}/available`).then((response) => response.data)
}

export function loginAsStudent(studentId: string) {
    return axios.post("/api/teacher/student-login", {
        studentId
    }).then(response => response.data)
}

export function undoImpersonation() {
    return axios.post("/api/teacher/undo-impersonation").then(response => response.data)
}

export async function studentRecords({ studentName, studentId } : { studentName: string, studentId: string }) {
    const res = await axios.get("/api/student/records", { params: { studentName, studentId } })
    return res.data
}

export async function getSectionWeights({ assignmentId }: { assignmentId: string }): Promise<Record<string, number>> {
    const res = await axios.get("/api/assignments/grade/topic/weights", {
        params: { assignmentId }
    })
    return res.data
}

export async function updateSectionWeights({ assignmentId, weights }: { assignmentId: string, weights: Record<string, number> }) {
    const res = await axios.put("/api/assignments/grade/topic/weights", {
        assignmentId,
        weights,
    })
    return res.data
}

export async function getHiddenModules({ classId }: { classId: string }) {
    const res = await axios.get("/api/teacher/hidden-modules", { params: { classId } })
    return res.data
}

export async function hideModule({ classId, module }: { classId: string, module: string }) {
    const res = await axios.post("/api/teacher/hidden-modules", { classId, module })
    return res
}

export async function revealModule({ classId, module }: { classId: string, module: string }) {
    const res = await axios.post("/api/teacher/hidden-modules", { classId, module, reveal: true })
    return res
}

export async function getSaqInfo({ classId }: { classId: string }) {
    const res = await axios.get("/api/teacher/saq-info", { params: { classId } })
    //check if res is a SaqInfoResponse
    if (res?.data?.saqOneAssigned !== undefined) {
        return res.data as SaqInfoResponse
    } else {
        return null
    }
}
