import EditLesson from "../EditLesson"
import { Field } from "formik"
import { LessonResponse } from "shared/lessons"
import styles from "../styles.module.scss"

const LessonSpecificBackground = ({ lesson, refreshLesson } : {lesson: LessonResponse, refreshLesson: () => Promise<void>}) => {
    const editing = process.env.REACT_APP_EDITING === "true"
 
   return (
     <div>
       <p className="text-start" dangerouslySetInnerHTML={{ __html: lesson?.background }}></p>
       {editing && <div>
         <EditLesson text="Edit Lesson Background" lesson={lesson} callback={refreshLesson} size="xl">
             <Field as={'textarea'} name="lessonBackground" defaultValue={lesson?.background} placeholder="Write Lesson Background" className={`${styles.lessonInput} col-10`} rows={20} />
         </EditLesson>
       </div>}
     </div>
   )
 }

export default LessonSpecificBackground
