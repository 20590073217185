import axios from "axios"
import { Type, LessonSubmissionResponse } from "shared/types/moduleTypes"

export function questionsForType(type: Exclude<Type, "Tier 0">) {
    if (type === "Tier 1") {
        return tier1Questions
    } else if (type === "Tier 2") {
        return tier2Questions
    } else {
        return apTierQuestions
    }
}

export const tier1Questions = [{ type: "Question", name: "Module" }, { type: "Question", name: "Research Question" }, { type: "Question", name: "Variables" }, { type: "Question", name: "Hypothesis" }, { type: "Question", name: "Your Methods" }, { type: "Table", name: "Your Data", rows: 10, columns: 6 }, { type: "Question", name: "Results" }, { type: "Question", name: "Conclusion" }, { type: "Question", name: "Questions You Have" }]
export const tier2Questions = [{ type: "Question", name: "Module" }, { type: "Question", name: "Research Question" }, { type: "Question", name: "Variables" }, { type: "Question", name: "Hypothesis" }, { type: "Question", name: "Your Methods" }, { type: "Table", name: "Your Data", rows: 10, columns: 6 }, { type: "Question", name: "Results" }, { type: "Question", name: "Conclusion" }, { type: "Question", name: "Possible Errors" }, { type: "Question", name: "Investigation Limitations" }, { type: "Question", name: "Additional Questions to be Researched" }]
export const apTierQuestions = [{ type: "Question", name: "Module" }, { type: "Question", name: "Research Question" }, { type: "Question", name: "Variables" }, { type: "Question", name: "Hypothesis" }, { type: "Question", name: "Your Methods" }, { type: "Table", name: "Your Data", rows: 10, columns: 6 }, { type: "Question", name: "Data Analysis" }, { type: "Question", name: "Results" }, { type: "Question", name: "Conclusion" }, { type: "Question", name: "Possible Errors" }, { type: "Question", name: "Investigation Limitations" }, { type: "Question", name: "Additional Questions to be Researched" }]

export interface PostProps {
    submissionId?: string
    module: string
    type: Type
    started: Date
    questionAnswers: string[]
    tableAnswers: string[]
    completed?: boolean
}

export async function post(props: PostProps) {
    const response = await axios.post("/api/simulations/pure-inquiry", props)
    return response
}

export interface PutProps {
    submissionId: string
    questionAnswers: string[]
    tableAnswers: string[]
    completed?: boolean
}

export async function put(props: PutProps) {
    const response = await axios.put("/api/simulations/pure-inquiry", props)
    return response
}

export interface GetFullProps {
    pureInquiryId: string
}

export async function getFull(props: GetFullProps): Promise<{ data: LessonSubmissionResponse}> {
    const response = await axios.get("/api/simulations/pure-inquiry", {
        params: props
    })
    return response.data
}

export interface GetProps {
    pureInquiryId: string
}

export interface GetResponse {
    id: string
    questionAnswers: string[]
    tableAnswers: string[]
    completed: string
    status: string
}

export async function get(props: GetProps): Promise<{ data: GetResponse }> {
    const response = await axios.get(`/api/simulations/pure-inquiry/${props.pureInquiryId}`)
    return response.data
}

export interface PutGradeProps {
    pureInquiryId: string
    comment: string
    grade: number
}

export async function putGrade(props: PutGradeProps) {
    return axios.put(`/api/simulations/pure-inquiry/${props.pureInquiryId}/grade`, props)
}

export interface PutQuestionProps {
    pureInquiryId: string
    comment: string
    questionNumber: number
    grade: number
}

export async function putQuestion(props: PutQuestionProps) {
    return axios.put(`/api/simulations/pure-inquiry/${props.pureInquiryId}/grade/question`, props)
}

export interface PutTableProps {
    pureInquiryId: string
    comment: string
    grade: number
}

export async function putTable(props: PutTableProps) {
    return axios.put(`/api/simulations/pure-inquiry/${props.pureInquiryId}/grade/table`, props)
}

const pureInquiry = {
    post,
    put,
    get,
    getFull,
    putGrade,
    putQuestion,
    putTable
}

export default pureInquiry
