function isInputElement(element: Element): element is HTMLInputElement {
    return element.tagName === "INPUT"
}
/**
 * Fill Form elements with initial data.
 */
export default function fillFormData(form: HTMLFormElement, formData: Record<string, string>) {
    for (const element of form?.elements ?? []) {
        if (isInputElement(element) && element.getAttribute("type") === "checkbox") {
            element.checked = formData[element.getAttribute("name")] === "on"
        }
        if (isInputElement(element) && element.getAttribute("type") === "radio") {
            if (element.checked) {
                element.value = formData[element.getAttribute("name")] ?? null
            }
        } else if (element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "SELECT") {
            (element as HTMLInputElement).value = formData[element.getAttribute("name")] ?? null
        }
    }
}
