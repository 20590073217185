import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import lessons, { LessonResponse } from "shared/lessons"
import * as teacher from "../../../../shared/routes/teacher"
import { ClassResponse, CreateProps, StudentResponse } from "../../../../shared/types/teacherTypes"
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm"
import { LessonVariety, MODULE_NO_ACCESS, Type } from "shared/types/moduleTypes"
import { AssignmentContext, AssignmentSection } from "components/Assignment/AssignmentContext"
import { Assessment } from "shared/types/assessmentTypes"
import moment from "moment"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import books from "shared/routes/curriculum/books"
import { AssignmentType } from "shared/types/assignmentTypes"
import FAIcon from "components/General/FAIcon"
import { Button } from "react-bootstrap"

const helpLinkVideos = [
    {
        "title": "Assigning Lessons",
        "link": "https://youtube.com/embed/AhKwZFMuHQA"
    },
    {
        "title": "Assigning Assessments Only",
        "link": "https://youtube.com/embed/1_QVT6jm0-c"
    }
]

const removeTags = (str: string) => {
    return str.replace(/<[^<>]+>/g, "")
}

/**
 * To verify the route is working correctly, checks all fields of this class with the route state
 * constructor allows some fields to be bypassed if they are not required
 */
export class CreateAssignmentProps {
    constructor() {
        this.assignmentType = "lesson"
        this.assessment = null
        this.lessonId = null
        this.topicId = null
        this.selectedStudent = null
    }
    topicId: string
    lessonId: string
    lessonVariety: LessonVariety
    module: string
    type: Type
    assessment: Assessment
    assignmentType: AssignmentType
    selectedStudent: StudentResponse
}

export default function CreateAssignment({activeClass}: {activeClass: ClassResponse}) {

    const [topicLessonId, setTopicLessonId] = React.useState<string>(null)
    const [selectedLesson, setSelectedLesson] = React.useState<LessonResponse | null>(null)
    const [assignmentTitle, setAssignmentTitle] = React.useState<string>("")

    const location = useLocation()
    const navigate = useNavigate()

    const state = location.state as CreateAssignmentProps
    const [activePart, setActivePart] = React.useState<AssignmentSection>(state.assignmentType)

    const [students, setStudents] = React.useState<StudentResponse[]>(null)

    const createAssignmentSubmit = (values) => {
        const assignmentType = state.assignmentType === "topic" ? "Topic" : state.lessonVariety === "Pure Inquiry" ? "Pure Inquiry" : "Guided"
        const data = {
            classID: activeClass.id,
            lessonVariety: assignmentType,
            assessmentId: state.assessment?.id,
            topicId: state.topicId,
            moduleName: state.module ?? (selectedLesson ? selectedLesson.moduleName : undefined),
            pureInquiryType: state.type ?? undefined,
            ...values,
            dueDate: values.dueDate ? moment(values.dueDate).utc().toDate() : undefined
        } as CreateProps
        if (state.lessonVariety === "Guided") {
            data.lessonId = values.assignmentType === "assessment" ? undefined : state.lessonId
            data.topicId = values.assignmentType === "assessment" || values.assignmentType === "lesson" ? undefined : state.topicId
        } else if (state.lessonVariety === "Topic") {
            data.lessonId = selectedLesson ? selectedLesson.id : undefined
            data.assessmentId = selectedLesson ? selectedLesson.assessment?.id : undefined
        }
        return teacher.createAssignment(data).then(() => {
            toast.success("The assignment has been created.")
            navigate("/dashboard/teacher/assignments")
        }).catch((err) => {
            if(typeof err === "object") err = err.response?.data.message
            if(err === MODULE_NO_ACCESS) {
                return window.location.href = `/simulations/moduleName=${state.module}/tab=challenge`
            }
            toast.error(err || "There was an error adding your assignment. Please try again.")
        })
    }

    React.useEffect(() => {
        if (state.lessonId || topicLessonId) {
            if (typeof state?.lessonId !== "string" && typeof topicLessonId !== "string") {
                navigate("/dashboard/teacher/assignments")
                toast.error("There was an error loading the assignment. Please try to reselect the assignment.")
                return
            }
            void lessons.findById(state.lessonId ?? topicLessonId).then((lesson) => {
                if (!lesson.userHasAccess) {
                    return window.location.href = `/simulations/moduleName=${state.module}/tab=challenge`
                }
                if (state.assignmentType === "assessment") {
                    lesson.title =  `${lesson.title} Assessment`
                }
                setSelectedLesson({...lesson, id: state.lessonId ?? topicLessonId})
            }).catch((err) => {
                if(typeof err === "object") err = err.response?.data.message
                navigate("/dashboard/teacher/assignments")
                toast.error(err || "There was an error loading the assignment. Please try to re-add the assignment.")
            })
        } else {
            setSelectedLesson(null)
        }

    }, [state.lessonId, topicLessonId, navigate, state.lessonVariety, state.module, state.type, state.assessment, state.assignmentType, state.topicId])

    React.useEffect(() => {
        if (activeClass?.id) {
            teacher.getStudents({ classID: activeClass.id }).then((response) => {
                setStudents(response)
            })
        } else {
            setStudents(null)
        }
    }, [activeClass?.id])

    const assignmentValues = {
        moduleName: state.module,
        type: state.type,
        lessonVariety: state.lessonVariety,
        guidedLesson: selectedLesson,
        activePart: activePart,
        setActivePart: setActivePart,
        assessmentOnly: state.assignmentType === "assessment",
        demoMode: false,
        reworkAssignmentMode: false,
    };
    
    React.useEffect(() => {
        if (state.topicId && state.assignmentType === "topic") {
            books.getTopic({ topicId: state.topicId })
            .then((topic) => {
                setAssignmentTitle(topic.name)
                const lessonId = topic?.sections?.find(section => section.lessonId !== null)?.lessonId
                setTopicLessonId(lessonId)
            })
        } else {
            setAssignmentTitle(removeTags(selectedLesson?.title ?? `Pure Inquiry - ${state.module}`))
        }
    }, [state.topicId, state.assignmentType, selectedLesson, state.module])

    return (<>
        <div className="text-start mb-3">
            <Button
                variant="link"
                size="lg"
                className="text-decoration-none"
                onClick={() => {
                    window.history.back()
                }}
            >
                <FAIcon iconName="arrow-left" /> Return to Search
            </Button>
        </div>
        <RelevantHelpModal videos={helpLinkVideos}/>
        <AssignmentContext.Provider value={assignmentValues}>
            <AssignmentForm
                submitButtonText="Create Assignment"
                initialValues={{
                    title: assignmentTitle,
                    dueDate: undefined,
                    notes: "",
                    assignmentType: state.assignmentType || "lesson",
                    studentAssessmentViewOption: activeClass?.studentAssessmentViewOption ?? "Grade",
                    students: state.selectedStudent ? [state.selectedStudent.uuid] : null,
                }}
                topicId={state.topicId}
                assessment={state.assessment}
                onSubmit={createAssignmentSubmit}
                students={students}
            />
        </AssignmentContext.Provider>
    </>)
}
