import AssignmentComponent from "components/Assignment/AssignmentComponent"
import { AssignmentContext, AssignmentContextType, AssignmentSection } from "components/Assignment/AssignmentContext"
import { useCallback, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { ClassResponse, TeacherAssignmentResponse } from "shared/types/teacherTypes"
import styles from "./styles.module.scss"
import { Button } from "react-bootstrap"

const DemoMode = ({ activeClass } :  { activeClass: ClassResponse }) => {
    const location = useLocation()
    const { assignment } = location.state as {assignment: TeacherAssignmentResponse}

    const navigate = useNavigate()
 
    const currentActivePart: AssignmentSection = assignment.topicId ? "topic" : (assignment.lesson || assignment.lessonVariety === "Pure Inquiry") ? "lesson" : "assessment"
    const assessmentOnly = !assignment.topicId && assignment.assessmentId && !assignment.lessonId

    const [activePart, setActivePart] = useState<AssignmentSection>(currentActivePart)

    const clearLocalStorage = () => {
        localStorage.removeItem("demoModeLessonResponses")
        localStorage.removeItem("demoModeAssessmentResponses")
    }

    const goBack = useCallback(() => {
        clearLocalStorage()
        navigate("/dashboard/teacher/assignments")
    }, [navigate])

    const contextValues: AssignmentContextType = {
        activePart: activePart,
        setActivePart: setActivePart,
        moduleName: assignment.moduleName,
        lessonVariety: assignment.lessonVariety,
        type: assignment.type,
        guidedLesson: assignment.lesson ?? null,
        assessmentOnly: assessmentOnly,
        demoMode: true,
        reworkAssignmentMode: false,
    }

    if (!assignment || !activeClass) return null

  return (
    <div className={`inherit-flex ${styles.demoModeFullScreen}`}>
       <div className={`container h-100 py-4 inherit-flex`}>
            <div className={`${styles.returnLink}`}>
                <Button 
                    variant="outline-primary" 
                    size="sm" 
                    onClick={() => {
                        navigate("/dashboard/teacher/assignments/grading/preview")
                        clearLocalStorage()
                    }}>
                    <i className="fas fa-arrow-left" /> Exit Demo Mode
                </Button>
            </div>
            <div>
                <AssignmentContext.Provider value={contextValues}>
                    <AssignmentComponent
                        topicId={assignment.topicId ?? null}
                        assessmentId={assignment.assessmentId ?? null}
                        moduleName={assignment.moduleName ?? null}
                        assessmentNumber={assignment.assessment?.number}
                        lessonVariety={assignment.lessonVariety}
                        type={assignment.type}
                        requireCompletion={activeClass.requireCompletion === "Yes"}
                        goBack={goBack}
                    ></AssignmentComponent>
                </AssignmentContext.Provider>
            </div>
       </div>
    </div>
  )
}

export default DemoMode
