import styles from "./requestQuote.module.scss"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import { Field, FormikProvider, useFormik } from "formik"
import React from "react"
import { useModel } from "@stem-sims/nexus"
import states from "shared/routes/states"
import { RequestQuoteEmailProps, requestQuoteEmail } from "shared/routes/requestQuote"
import { toast } from "react-toastify"
import FloatingPage from "components/General/FloatingPage"
import * as Yup from "yup"

const { input, requestQuote } = styles

export default function RequestQuote() {
    const { response: stateList } = useModel({
        model: states.get
    })

    const formik = useFormik<RequestQuoteEmailProps>({
        initialValues: {
            organizationName: "",
            contactName: "",
            contactEmail: "",
            organizationType: "school",
            state: "",
            studentCount: "",
            moreDetails: "",
        },
        validationSchema: Yup
            .object()
            .shape({
                contactName: Yup
                    .string()
                    .label("Contact Name")
                    .required(),
                contactEmail: Yup
                    .string()
                    .email()
                    .label("Contact Email")
                    .required(),
                organizationName: Yup
                    .string()
                    .label("Organization Name")
                    .required(),
                studentCount: Yup
                    .string()
                    .label("Number of Students")
                    .required(),
            }),

        onSubmit: async (values) => {
            try {
                const response = await requestQuoteEmail(values)
                toast.success(response)
            } catch (e) {
                toast.error(`Error sending your request, please try again.`)
            }
        }
    })

    return (<>
        <FloatingPage className={requestQuote}>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={{ span: 8, offset: 2 }}>
                            <Row>

                                <Col lg={12}>
                                    <h1 className="h2">Talk To A STEM Education Expert</h1>
                                </Col>

                                <Col lg={12}>
                                    Tell us more about your organization. You will be sent a link to set up a meeting with a STEM Education Expert to discuss how you can best implement STEM Sims. You will get a quote and be able to get started with a trial. You can also email our team at <a href={"mailto:quotes@stemsims.com"}>quotes@stemsims.com</a>.
                                </Col>

                                <Form.Group as={Col} lg={12} className="mb-3 text-start mt-3">
                                    <Form.Label>Organization Name</Form.Label>
                                    <Form.Control
                                        as={Field}
                                        name="organizationName"
                                        placeholder="New York Public Schools"
                                        className={`${input}`}
                                        isInvalid={formik.touched.organizationName && !!formik.errors.organizationName}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-center">
                                        {formik.errors.organizationName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} lg={12} className="mb-3 text-start">
                                    <Form.Label>Contact Name</Form.Label>
                                    <Form.Control
                                        as={Field}
                                        name="contactName"
                                        placeholder="Tammy Simms"
                                        className={`${input}`}
                                        isInvalid={formik.touched.contactName && !!formik.errors.contactName}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-center">
                                        {formik.errors.contactName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} lg={12} className="mb-3 text-start">
                                    <Form.Label>Contact Email</Form.Label>
                                    <Form.Control
                                        as={Field}
                                        name="contactEmail"
                                        placeholder="tsmith@nyps.edu"
                                        className={`${input}`}
                                        isInvalid={formik.touched.contactEmail && !!formik.errors.contactEmail}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-center">
                                        {formik.errors.contactEmail}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} lg={6} className="mb-3 text-start">
                                    <Form.Label>Organization Type</Form.Label>
                                    <Form.Select
                                        name="organizationType"
                                        onChange={formik.handleChange}
                                        value={formik.values.organizationType}
                                    >
                                        <option value="school">School</option>
                                        <option value="district">District</option>
                                        <option value="other">Other</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} lg={6} className="mb-3 text-start">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        name="state"
                                        onChange={formik.handleChange}
                                        value={formik.values.state}
                                    >
                                        <option value="" disabled>Select A State</option>
                                        {stateList?.map((state) => (
                                            <option value={state}>{state}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} lg={12} className="mb-3 text-start">
                                    <Form.Label>Number of Students</Form.Label>
                                    <Form.Control
                                        as={Field}
                                        name="studentCount"
                                        className={`${input}`}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} lg={12} className="mb-3 text-start">
                                    <Form.Label>More Details</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Enter additional information about your needs."
                                        className={`${input}`}
                                        name="moreDetails"
                                        onChange={formik.handleChange}
                                        value={formik.values.moreDetails}
                                    ></Form.Control>
                                </Form.Group>
                                <div>
                                    <Button variant="theme" className="mt-2" type="submit">Send Your Request</Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </FormikProvider>
        </FloatingPage>
    </>)
}
